import { useCallback } from 'react';
import { AxiosError } from 'axios';
import {
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';
import { useApi } from '../../api/ApiProvider';
import { AppGroup, Application, Id, Period, Status } from './types';
import { ApiResponse } from '../../api/types';

export type MonitorTableData = {
  periods: {
    type: 'DAYS' | 'MONTHS' | 'YEARS';
    items: {
      [key in Id]: Period;
    };
  };
  applications: {
    [key in Id]: Application;
  };
  applicationGroups: {
    [key in Id]: AppGroup;
  };
  statuses: {
    [key in Id]: Status;
  };
};

type QueryResult = ApiResponse<MonitorTableData>;

export type ViewType = 'all-time' | 'last-twelve-months' | 'last-thirty-days';

export const isViewType = (value: string): value is ViewType => {
  const allOptions: { [key in ViewType]: unknown } = {
    'all-time': null,
    'last-twelve-months': null,
    'last-thirty-days': null,
  };
  return value in allOptions;
};

type UseMonitorTableDataQueryArgs = {
  viewType: ViewType;
};

export const createMonitorTableDataCacheKey = (viewType: ViewType) => [
  viewType,
  'monitorTableData',
];

export const useMonitorTableDataQuery = (
  args: UseMonitorTableDataQueryArgs,
  opts?: UseQueryOptions<QueryResult, AxiosError>,
) => {
  const { viewType } = args;
  const api = useApi();

  const queryFn = async ({ signal }: { signal?: AbortSignal }) => {
    const { data } = await api.get<QueryResult>(`/statistics/${viewType}`, {
      signal,
    });

    return data;
  };

  const query = useQuery<QueryResult, AxiosError>(
    createMonitorTableDataCacheKey(viewType),
    queryFn,
    opts,
  );

  return query;
};

export const useInvalidateMonitorTableDataQuery = (viewType: ViewType) => {
  const queryClient = useQueryClient();

  const invalidate = useCallback(
    () =>
      queryClient.invalidateQueries(createMonitorTableDataCacheKey(viewType)),
    [queryClient, viewType],
  );

  return invalidate;
};
