import { useCallback } from 'react';
import { AxiosError, AxiosRequestConfig } from 'axios';
import {
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';
import { ApiResponse } from '../../api/types';
import { useApi } from '../../api/ApiProvider';
import { Id } from '../applicationMonitorPage/types';
import { colors } from '../../mocks/data';

export const createApplicationsCacheKey = () => ['applications'];

export type AppData = {
  id: Id;
  name: string;
  applicationGroupId: Id;
  color: string;
};

type ApplicationsData = AppData[];

type QueryResult = ApiResponse<ApplicationsData>;

export const useApplicationsTicketQuery = (
  opts?: UseQueryOptions<QueryResult, AxiosError>,
  axios?: AxiosRequestConfig,
) => {
  const api = useApi();

  const queryFn = async ({ signal }: { signal?: AbortSignal }) => {
    const { data } = await api.get<QueryResult>(`/application/ticket`, {
      ...axios,
      signal,
    });
    return data.map(({ color, ...app }, index) => ({
      ...app,
      // TODO: cleanup this fallback
      color: color ?? colors[index % colors.length],
    }));
  };

  const query = useQuery<QueryResult, AxiosError>(
    createApplicationsCacheKey(),
    queryFn,
    opts,
  );

  return query;
};

export const useInvalidateApplicationsQuery = () => {
  const queryClient = useQueryClient();

  const invalidate = useCallback(
    () => queryClient.invalidateQueries(createApplicationsCacheKey()),
    [queryClient],
  );

  return invalidate;
};
