import { context, RestRequest } from 'msw';
import { randomNumber } from '../../utils/mockHelpers';
import { appGroupNames, appNames, ticketTypes } from '../data';

const DISABLE_APPLICATIONS_GROUPS = true;

const generateTickets = (
  index: number,
  name: string,
  ticketType: string,
  disableApplicationGroups: boolean,
) =>
  [
    {
      id: String(index),
      name,
      applicationGroupId: disableApplicationGroups
        ? null
        : String(index % appGroupNames.length),
      ticketsIn: randomNumber(10),
      ticketsOut: randomNumber(15),
      remainInTotal: randomNumber(25),
      supportLevel: 'L2',
      ticketType,
    },
    {
      id: String(index),
      name,
      applicationGroupId: disableApplicationGroups
        ? null
        : String(index % appGroupNames.length),
      ticketsIn: randomNumber(10),
      ticketsOut: randomNumber(15),
      remainInTotal: randomNumber(25),
      supportLevel: 'L3',
      ticketType,
    },
  ].slice(0, index % 3 === 0 ? 1 : undefined);

export const response = (req: RestRequest) => {
  const supportLevelParam = req.url.searchParams.get('supportLevel');
  const selectedAppsParam = req.url.searchParams.get('selectedApps');

  const responseData = {
    applicationGroups: appGroupNames.map((name, index) => ({
      id: String(index),
      name,
    })),
    tickets: ticketTypes
      .flatMap((ticketType) =>
        appNames.flatMap((name, index) =>
          index === 0 && ticketType === 'INCIDENT'
            ? []
            : generateTickets(
                index,
                name,
                ticketType,
                DISABLE_APPLICATIONS_GROUPS,
              ),
        ),
      )
      .filter(({ supportLevel }) =>
        supportLevelParam ? supportLevelParam === supportLevel : true,
      )
      .filter(({ name }) =>
        selectedAppsParam ? selectedAppsParam === name : true,
      ),
  };

  return context.json(responseData);
};
