import {
  Button,
  Center,
  FormControl,
  VStack,
  Input,
  FormLabel,
  FormErrorMessage,
} from '@chakra-ui/react';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Credentials } from '../providers/authProvider/types';
import { GeopostIcon } from '../icons/GeopostIcon';

type LoginPageProps = {
  onSignIn?: (credentials: Credentials) => Promise<void>;
};

// NOTE: Component only needed on lower envs
export const LoginPage: FC<LoginPageProps> = ({ onSignIn }) => {
  const [signingIn, setSigningIn] = useState(false);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  const onSubmit = async (values: any) => {
    try {
      setSigningIn(true);
      await onSignIn?.(values);
    } finally {
      setSigningIn(false);
    }
  };

  return (
    <Center height="100vh" width="full">
      <VStack as="form" onSubmit={handleSubmit(onSubmit)} spacing={8}>
        <GeopostIcon width={'96'} />
        <FormControl isInvalid={!!errors.username}>
          <FormLabel>Login</FormLabel>
          <Input
            {...register('username', {
              required: {
                message: 'Login field should not be empty',
                value: true,
              },
            })}
          />

          <FormErrorMessage>
            {errors.username?.message?.toString()}
          </FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!errors.password}>
          <FormLabel>Password</FormLabel>
          <Input
            {...register('password', {
              required: {
                message: 'Password field should not be empty',
                value: true,
              },
            })}
            type="password"
            autoComplete="current-password"
          />

          <FormErrorMessage>
            {errors.password?.message?.toString()}
          </FormErrorMessage>
        </FormControl>
        <Button type="submit" isLoading={signingIn}>
          Sign in
        </Button>
      </VStack>
    </Center>
  );
};
