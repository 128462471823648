import { context } from 'msw';
import { appGroupNames, appNames } from '../data';

export const response = () =>
  context.json(
    appNames.map((name, index) => ({
      id: String(index),
      applicationGroupId: String(index % appGroupNames.length),
      name,
    })),
  );
