import { AxiosInstance } from 'axios';
import { createContext, FC, ReactNode, useContext, useState } from 'react';
import invariant from 'tiny-invariant';

import { createApi } from './api';

const defaultValue = {};

const ApiContext = createContext<AxiosInstance>(defaultValue as AxiosInstance);

export const ApiProvider: FC<{ children: ReactNode }> = (props) => {
  const { children = null } = props;

  const [api] = useState(createApi);

  return <ApiContext.Provider value={api}>{children}</ApiContext.Provider>;
};

export const useApi = () => {
  const api = useContext(ApiContext);

  invariant(
    api !== defaultValue,
    `"useApi" hook used outside of its' provider`,
  );

  return api;
};
