import axios, { AxiosRequestConfig } from 'axios';
import { configuration } from '../configuration';

const envApiUrl = configuration.baseUrl ?? '';

export const apiBaseUrl = envApiUrl.endsWith('/')
  ? envApiUrl.replace(/\/+$/g, '')
  : envApiUrl;

export const createApi = (config?: AxiosRequestConfig) =>
  axios.create({
    baseURL: apiBaseUrl,
    ...config,
  });
