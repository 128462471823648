import { context } from 'msw';

const NUMBER_OF_MONTHS = 6;

export const response = () => {
  let currentYear = new Date().getFullYear();
  let currentMonth = new Date().getMonth();
  let date: { year: number; month: number }[] = [];

  for (let i = 0; i < NUMBER_OF_MONTHS; i++) {
    if (currentMonth < 1) {
      currentMonth = 12;
      currentYear -= 1;
    }

    date.push({
      year: currentYear,
      month: currentMonth,
    });
    currentMonth -= 1;
  }

  return context.json(date);
};
