export const convertMsToHM = (milliseconds: number) => {
  let seconds = Math.floor(milliseconds / 1000);
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);
  let days = Math.floor(hours / 24);

  seconds = seconds % 60;

  minutes = minutes % 60;

  hours = hours % 24;

  return [
    days && `${days}d`,
    hours && `${hours}h`,
    minutes && `${minutes}m`,
    seconds && `${seconds}s`,
  ]
    .filter(Boolean)
    .join(' ');
};

export const millisecondsToHours = (millis: number) => {
  const millisPerHour = 1000 * 60 * 60;

  return millis / millisPerHour;
};

export const getTimeInSeconds = () => {
  return Math.round(new Date().getTime() / 1000);
};
