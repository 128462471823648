import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { AppPage } from '../ui-components/AppPage';
import { TicketStatisticOverview } from './ticketStatisticPage/TicketStatisticOverview';
import { TicketStatisticTimeOverview } from './ticketStatisticPage/TicketStatisticTimeOverview';

type TicketStatisticPageProps = {};

export const TicketStatisticPage: FC<TicketStatisticPageProps> = () => {
  return (
    <AppPage>
      <Routes>
        <Route path="/" element={<TicketStatisticOverview />} />
        <Route
          path="resolution-time"
          element={<TicketStatisticTimeOverview />}
        />
      </Routes>
    </AppPage>
  );
};
