import { context, RestRequest } from 'msw';
import { randomNumber } from '../../utils/mockHelpers';
import { appNames, colors } from '../data';

const periodCount = 6;

const generateEntries = (
  periodId: number,
  appId: number,
  supportLevel: number,
  ticketType: number,
) => ({
  periodId: String(periodId),
  applicationId: String(appId),
  supportLevel: supportLevel % 2 === 0 ? 'L2' : 'L3',
  numberOfTickets: randomNumber(20),
  ticketType: ticketType % 2 === 0 ? 'INCIDENT' : 'SERVICE_REQUEST',
  remainInTotalLessThanMonth: randomNumber(20, 3),
  remainInTotalOneToTwoMonths: randomNumber(20, 3),
  remainInTotalTwoToThreeMonths: randomNumber(20, 3),
  remainInTotalMoreThanThreeMonths: randomNumber(20, 3),
});

export const response = (req: RestRequest) => {
  const supportLevelParam = req.url.searchParams.get('supportLevel');

  const response = {
    periods: Array.from({ length: periodCount })
      .map((_, index) => {
        const currentYear = new Date().getFullYear();

        const year = currentYear + Math.floor(index / 12);
        const month = (index + 1) % 12;

        return {
          id: String(index),
          dateTime: `${year}-${month.toString().padStart(2, '0')}`,
        };
      })
      .reverse(),

    applications: appNames.map((name, index) => ({
      id: String(index),
      name,
      color: colors[index],
      applicationGroupId: String(index % appNames.length),
    })),

    entries: (() => {
      let result = [];

      for (let periodId = 0; periodId < periodCount; ++periodId) {
        for (let appId = 0; appId < appNames.length; ++appId) {
          for (let ticketType = 1; ticketType <= 2; ++ticketType) {
            if (supportLevelParam) {
              const supportLevelAsNumber = supportLevelParam === 'L2' ? 2 : 1;
              result.push(
                generateEntries(
                  periodId,
                  appId,
                  supportLevelAsNumber,
                  ticketType,
                ),
              );
              continue;
            }

            for (let supportLevel = 1; supportLevel <= 2; ++supportLevel) {
              result.push(
                generateEntries(periodId, appId, supportLevel, ticketType),
              );
            }
          }
        }
      }
      return result;
    })(),
  };

  return context.json(response);
};
