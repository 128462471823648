import {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import invariant from 'tiny-invariant';

import { useEnchancedApi } from './useEnchancedApi';
import { useAutoLogout } from './useAutoLogout';
import { useUserScope } from './useUserScope';
import { useNavigate } from 'react-router-dom';
import { getKeycloak } from '../../keycloack';
import { useSessionStorage } from 'react-use';

type Token = string;
interface SignInMutationQueryResult {
  accessToken: Token;
  refreshToken: Token;
}

interface RefreshTokenResponse extends SignInMutationQueryResult {}

type AuthContextState =
  | { state: 'loading' }
  | { state: 'signedOut'; signIn?: () => void }
  | { state: 'signedIn'; scopes: string[]; signOut?: () => void };

type AuthState = AuthContextState['state'];

const defaultValue: AuthContextState = {
  state: 'signedOut',
};

const AuthContext = createContext<AuthContextState>(defaultValue);

interface AuthSSOProviderProps {}

export const AuthSSOProvider: FC<
  React.PropsWithChildren<AuthSSOProviderProps>
> = ({ children }) => {
  const navigate = useNavigate();
  const [referrer, setReferrer] = useSessionStorage('referrer', '');

  const { current: keycloak } = useRef(getKeycloak()!);
  const [currentAuthState, setCurrentAuthState] =
    useState<AuthState>('loading');

  const [accessToken, setAccessToken] = useState<Token>('');
  const [refreshToken, setRefreshToken] = useState<Token>('');

  const scopes = useUserScope({ token: accessToken });

  const api = useEnchancedApi({
    onRefreshToken: async (api) => {
      try {
        const { data: regenerated } = await api.post<RefreshTokenResponse>(
          '/token/refresh',
          {},
          {
            headers: {
              Authorization: `Bearer ${refreshToken}`,
              RerfeshingTokens: true,
            },
          },
        );

        setAccessToken(regenerated.accessToken);
        setRefreshToken(regenerated.refreshToken);
      } catch (e) {
        signOut();
      }
    },
    token: accessToken,
  });

  const goToSSOPage = useCallback(async () => {
    const { origin, pathname, search } = window.location;
    setReferrer(`${pathname}${search}`);

    await keycloak?.login({
      redirectUri: origin,
    });
  }, [keycloak, setReferrer]);

  const navigateToReferrer = useCallback(() => {
    setReferrer('');
    navigate(referrer ?? '/');

    /*
     * NOTE: when we put navigate in array dependecies we are causing the loop,
     * current keycloak setup not allow for any kind of different url than current origin,
     * so we are not able to pass any query parameter because we will cause invalid redirect_uri error
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const obtainAppTokens = useCallback(
    async (accessToken: string, refreshToken: string) => {
      try {
        const {
          data: { accessToken: appAccessToken, refreshToken: appRefreshToken },
        } = await api.post('/token', {
          accessToken,
          refreshToken,
        });

        setAccessToken(appAccessToken);
        setRefreshToken(appRefreshToken);
        setCurrentAuthState('signedIn');
      } catch (e) {
        setCurrentAuthState('signedOut');
      }
    },
    [api, setAccessToken, setRefreshToken, setCurrentAuthState],
  );

  useEffect(() => {
    async function initializeKeycloack() {
      try {
        await keycloak.init({ checkLoginIframe: false });
        const { token, refreshToken, authenticated } = keycloak;

        if (authenticated && token && refreshToken) {
          await obtainAppTokens(token, refreshToken);
          navigateToReferrer();
          return;
        }

        goToSSOPage();
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('Cannot initialize keycloak instance', e);
      }
    }

    initializeKeycloack();
  }, [
    setRefreshToken,
    navigateToReferrer,
    keycloak,
    goToSSOPage,
    obtainAppTokens,
  ]);

  const signIn = useCallback(async () => {}, []);

  const signOut = useCallback(async () => {
    const { origin } = window.location;

    try {
      await api.post('/token/logout', {
        accessToken,
        refreshToken,
      });
    } finally {
      await keycloak!.logout({
        redirectUri: origin,
      });
    }
  }, [accessToken, api, keycloak, refreshToken]);

  useEffect(() => {}, []);

  useAutoLogout({
    signOut,
    refreshToken,
  });

  const authState: AuthContextState = useMemo(() => {
    switch (currentAuthState) {
      case 'loading': {
        return {
          state: 'loading',
        };
      }
      case 'signedOut': {
        return { state: 'signedOut', signIn };
      }

      case 'signedIn': {
        return { state: 'signedIn', signOut, scopes };
      }
    }
  }, [currentAuthState, scopes, signIn, signOut]);

  return (
    <AuthContext.Provider value={authState}>{children}</AuthContext.Provider>
  );
};

export const useAuthSSO = () => {
  const auth = useContext(AuthContext);

  invariant(
    auth !== defaultValue,
    `"useAuthSSO" hook used outside of its' provider`,
  );

  return auth;
};
