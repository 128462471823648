import {
  Alert,
  AlertIcon,
  Box,
  Center,
  Checkbox,
  Flex,
  HStack,
  Spinner,
  Stack,
  Text,
  Grid,
  GridItem,
} from '@chakra-ui/react';
import { FC, useCallback, useState } from 'react';
import { useTicketManagementChartsQuery } from './useTicketManagementChartsQuery';

import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { format } from 'date-fns';
import { AppData } from '../applicationMonitorPage/useApplicationsQuery';
import { useMeasure } from 'react-use';
import {
  TicketSupportLevel,
  ticketSupportLevels,
  TicketType,
  ticketTypes,
} from './ticketManagementDomain';
import { toggleElement } from '../../utils/arrayHelpers';
import {
  map,
  pipe,
  find,
  propEq,
  allPass,
  groupBy,
  sum,
  join,
  uniqBy,
  eqBy,
  values,
} from 'ramda';
import { ChartRecord } from './types';

type TicketsRemainingChartProps = {
  applications: AppData[];
  periodValue: string;
  supportLevel: TicketSupportLevel | null;
};

export const TicketsRemainingChart: FC<TicketsRemainingChartProps> = (
  props,
) => {
  const { periodValue, applications, supportLevel } = props;

  const [selectedAppIds, setSelectedAppIds] = useState<string[]>([]);

  const chartDetailsQuery = useTicketManagementChartsQuery({
    startPeriodValue: periodValue,
    supportLevel,
  });

  const [chartContainerRef, chartContainerDimens] =
    useMeasure<HTMLDivElement>();

  const { data } = chartDetailsQuery;

  const renderDot = useCallback((props: any) => {
    const { cx, cy, stroke, dataKey, key } = props;
    const isActive = !!key.match('activePoint');
    const isSR = !!dataKey.match('SERVICE_REQUEST');

    const border = isActive
      ? {
          stroke: 'black',
          stokeWidth: 3,
        }
      : {};

    return (
      <svg version="1.0">
        {isSR ? (
          <rect
            x={cx - 5}
            y={cy - 5}
            width="10"
            height="10"
            fill={stroke}
            {...border}
          />
        ) : (
          <circle cx={cx} cy={cy} r="5" fill={stroke} {...border} />
        )}
      </svg>
    );
  }, []);

  if (chartDetailsQuery.isError) {
    return (
      <Center>
        <Alert>
          <HStack>
            <AlertIcon />
            <Text>
              Error:
              {(chartDetailsQuery.error as Error).message ??
                'Error occurred. Please, refresh the page'}
            </Text>
          </HStack>
        </Alert>
      </Center>
    );
  }

  if (chartDetailsQuery.isLoading) {
    return (
      <Center height="full" width="full">
        <Spinner />
      </Center>
    );
  }

  const { periods, entries } = data!;

  const sortedPeriods = Array.from(periods).sort(
    ({ dateTime: a }, { dateTime: b }) => {
      return a > b ? 1 : -1;
    },
  );

  const appsWithIncidents = applications.filter(({ id }) => {
    return entries.some(
      ({ numberOfTickets, applicationId }) =>
        numberOfTickets > 0 && applicationId === id,
    );
  });

  const chartData = sortedPeriods.map((period) => {
    const { id, dateTime } = period;

    const periodRecords = entries.filter(({ periodId }) => periodId === id);

    const dataObj = Object.fromEntries(
      pipe(
        () =>
          appsWithIncidents.flatMap(({ id }) =>
            ticketTypes.flatMap((type) =>
              ticketSupportLevels.map((level) => [id, type, level]),
            ),
          ),
        map(([id, type, level]): ChartRecord => {
          const matchedPeriodRecord = find<ChartRecord>(
            allPass([
              propEq<any>('applicationId', id),
              propEq<any>('ticketType', type),
              propEq<any>('supportLevel', level),
            ]),
          )(periodRecords);

          const defaultPeriodRecord: ChartRecord = {
            applicationId: id,
            numberOfTickets: 0,
            supportLevel: level,
            ticketType: type as TicketType,
            periodId: id,
            remainInTotalLessThanMonth: 0,
            remainInTotalMoreThanThreeMonths: 0,
            remainInTotalOneToTwoMonths: 0,
            remainInTotalTwoToThreeMonths: 0,
          };

          return matchedPeriodRecord ?? defaultPeriodRecord;
        }),
        groupBy<ChartRecord, string>(
          ({ applicationId, ticketType }) => `${applicationId}-${ticketType}`,
        ),
        values,
        map((groupedTickets) => {
          const numberOfTicketsSum = sum(
            groupedTickets.map(({ numberOfTickets }) => numberOfTickets),
          );

          const supportLevel = join(
            '+',
            uniqBy(
              eqBy(String),
              groupedTickets.map(({ supportLevel }) => supportLevel),
            ),
          );

          const [ticket] = groupedTickets;

          return {
            ...ticket,
            numberOfTickets: numberOfTicketsSum,
            supportLevel: supportLevel,
          };
        }),
        map(({ applicationId, supportLevel, numberOfTickets, ticketType }) => [
          `${applicationId}-${supportLevel}-${ticketType}`,
          numberOfTickets,
        ]),
      )(),
    );

    return {
      ...dataObj,
      name: format(new Date(dateTime), 'MMM-yyyy'),
    };
  });

  const renderLineChart = () => {
    return (
      <ResponsiveContainer
        width={chartContainerDimens.width}
        height={chartContainerDimens.height}
      >
        <LineChart
          data={chartData}
          margin={{ top: 25, right: 40, bottom: 5, left: 0 }}
        >
          <CartesianGrid stroke="#ccc" strokeDasharray="2 2" />
          <XAxis dataKey="name" fontSize={12} interval={0} />
          <YAxis scale="linear" fontSize={12} interval={0} />
          <Tooltip />

          <Legend
            verticalAlign="bottom"
            align="right"
            layout="horizontal"
            iconSize={20}
            wrapperStyle={{ right: '0', cursor: 'pointer' }}
            content={(props: any) => {
              const { payload } = props;

              return (
                <Grid templateColumns="repeat(4, 1fr)" gap={1}>
                  {uniqBy<any, any>(
                    ({ dataKey }) => dataKey.split('-')[0],
                    payload,
                  ).map((line: any) => {
                    const { color, dataKey, value, inactive } = line;
                    const valueWithoutTicketType = value.replace(/\(\w+\)/, '');
                    const appId = dataKey.split('-')[0];

                    return (
                      <GridItem>
                        <Checkbox
                          sx={{
                            span: {
                              fontSize: 'sm',
                            },
                          }}
                          name="asdasd"
                          whiteSpace="nowrap"
                          key={dataKey}
                          isChecked={!inactive}
                          color={inactive ? 'gray' : color}
                          colorScheme="red"
                          onChange={() => {
                            if (
                              selectedAppIds.length === 1 &&
                              selectedAppIds[0] === dataKey
                            ) {
                              return;
                            }

                            setSelectedAppIds((oldIds) =>
                              toggleElement(oldIds, appId),
                            );
                          }}
                        >
                          {valueWithoutTicketType}
                        </Checkbox>
                      </GridItem>
                    );
                  })}
                </Grid>
              );
            }}
          />
          {Object.keys(chartData[0]).map((chartDataKey) => {
            const [id, sl, type] = chartDataKey.split('-');
            const app = applications.find(({ id: appId }) => id === appId);

            if (!app) return null;
            const { name, color } = app;

            return (
              <Line
                key={`${id}-${sl}-${type}`}
                connectNulls
                dataKey={`${id}-${sl}-${type}`}
                stroke={color}
                dot={renderDot}
                activeDot={renderDot}
                name={`${name} (${type === 'INCIDENT' ? 'Inc' : 'SR'})`}
                hide={
                  !selectedAppIds.some((appId) => {
                    return appId.startsWith(id);
                  })
                }
              />
            );
          })}
        </LineChart>
      </ResponsiveContainer>
    );
  };

  return (
    <Stack height="full">
      <Stack marginTop={10} border="1px solid #e6e7e8" padding={0} flex={1}>
        <Flex
          backgroundColor="#e6e7e8"
          textAlign="end"
          width="full"
          paddingLeft={4}
          paddingRight={4}
          paddingTop={3}
          paddingBottom={3}
          justifyContent="space-between"
        >
          <Text color="#414042">Tickets remaining in Backlog</Text>
          <Text color="#808285">
            {format(new Date(periodValue), 'MMMM yyyy')}
          </Text>
        </Flex>

        <Box ref={chartContainerRef} width="100%" flex={1}>
          {appsWithIncidents.length !== 0 ? (
            renderLineChart()
          ) : (
            <Stack>
              <Center height={200} width="full">
                <Text>
                  There haven’t been any issues remained in the backlog
                </Text>
              </Center>
            </Stack>
          )}
        </Box>
      </Stack>
    </Stack>
  );
};
