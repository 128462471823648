import { createIcon } from '@chakra-ui/react';

export const ContactUsIcon = createIcon({
  displayName: 'ContactUsIcon',
  viewBox: '0 0 68 68',
  path: [
    <path
      fill="#414042"
      d="M37,34c-0.403,0-0.784-0.247-0.935-0.646c-0.196-0.517,0.064-1.094,0.581-1.29
							c2.858-1.083,4.854-4.075,4.854-7.276C41.5,20.567,38.065,17,34,17s-7.5,3.567-7.5,7.789c0,1.259,0.127,2.106,0.425,2.831
							c0.21,0.511-0.034,1.095-0.544,1.305c-0.512,0.211-1.096-0.034-1.305-0.544c-0.51-1.24-0.575-2.571-0.575-3.591
							C24.5,19.483,28.851,15,34,15s9.5,4.483,9.5,9.789c0,4.014-2.527,7.775-6.146,9.146C37.238,33.979,37.118,34,37,34z"
    />,
    <path
      fill="#414042"
      d="M49,52c-0.552,0-1-0.448-1-1c0-7.411-6.019-13-14-13c-3.563,0-6.676,1.033-9.001,2.988
							C21.635,43.815,20,47.09,20,51c0,0.552-0.448,1-1,1s-1-0.448-1-1c0-4.537,1.868-8.313,5.711-11.543
							C26.403,37.195,29.96,36,34,36c9.122,0,16,6.449,16,15C50,51.552,49.552,52,49,52z"
    />,
    <path
      fill="#DC0032"
      d="M23.606,32c-0.345,0-0.681-0.179-0.867-0.5C21.602,29.533,21,27.285,21,25
						c0-7.168,5.832-13,13-13s13,5.832,13,13c0,1.462-0.241,2.898-0.717,4.268c-0.181,0.522-0.753,0.796-1.273,0.617
						c-0.522-0.182-0.798-0.751-0.617-1.273C44.796,27.454,45,26.238,45,25c0-6.065-4.935-11-11-11s-11,4.935-11,11
						c0,1.934,0.509,3.835,1.471,5.5c0.276,0.478,0.113,1.089-0.365,1.366C23.949,31.957,23.776,32,23.606,32z"
    />,
    <path
      fill="#DC0032"
      d="M22.127,30C19.3,30,17,27.757,17,25s2.3-5,5.127-5l0.137-0.001
						c0.198-0.002,0.3-0.002,0.533,0.038c0.544,0.095,0.909,0.612,0.814,1.156c-0.094,0.544-0.614,0.906-1.156,0.814
						c-0.07-0.012-0.1-0.01-0.159-0.008L22.127,22C20.403,22,19,23.346,19,25s1.403,3,3.127,3c0.162,0,0.283-0.002,0.412-0.02
						c0.544-0.073,1.051,0.31,1.125,0.858c0.074,0.547-0.311,1.051-0.858,1.125C22.594,29.991,22.393,30,22.127,30z"
    />,
    <path
      fill="#DC0032"
      d="M46,30c-0.282,0-0.5-0.01-0.784-0.06c-0.544-0.095-0.908-0.613-0.813-1.157
						c0.095-0.543,0.614-0.906,1.157-0.813C45.714,27.998,45.828,28,46,28c1.654,0,3-1.346,3-3s-1.346-3-3-3
						c-0.178,0.002-0.271-0.001-0.43,0.028c-0.542,0.099-1.064-0.261-1.164-0.804c-0.099-0.543,0.261-1.064,0.804-1.164
						C45.518,20.004,45.723,20,46,20c2.757,0,5,2.243,5,5S48.757,30,46,30z"
    />,
    <path
      fill="#DC0032"
      d="M33,32h-9.394c-0.552,0-1-0.448-1-1s0.448-1,1-1H33c0.552,0,1,0.448,1,1S33.552,32,33,32z"
    />,
  ],
});
