import { FC, useCallback } from 'react';

import { AppPage } from '../ui-components/AppPage';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { AppsOverview } from './applicationMonitorPage/AppsOverview';
import { IncidentOverview } from './applicationMonitorPage/IncidentOverview';
import { PeriodType } from './applicationMonitorPage/types';

export const createIncidentDetailsPath = ({
  appIdSegment,
  periodTypeSegment,
  periodValueSegment,
}: Record<
  'appIdSegment' | 'periodTypeSegment' | 'periodValueSegment',
  string
>) =>
  `app/${appIdSegment}/incidents/${periodTypeSegment}/${periodValueSegment}`;

export type ApplicationMonitorPageProps = {};

export const ApplicationMonitorPage: FC<ApplicationMonitorPageProps> = (
  props,
) => {
  const navigate = useNavigate();

  const onStatusClick = useCallback(
    (args: {
      statusId: string;
      appId: string;
      period: string;
      periodType: PeriodType;
    }) => {
      const { appId, period, periodType } = args;

      navigate(
        createIncidentDetailsPath({
          appIdSegment: appId,
          periodTypeSegment: periodType,
          periodValueSegment: period,
        }),
      );
    },
    [navigate],
  );

  return (
    <AppPage>
      <Routes>
        <Route index element={<AppsOverview onStatusClick={onStatusClick} />} />
        <Route
          path={createIncidentDetailsPath({
            appIdSegment: ':appId',
            periodTypeSegment: ':periodType',
            periodValueSegment: ':periodValue',
          })}
          element={<IncidentOverview />}
        />
        <Route
          path="*"
          element={<AppsOverview onStatusClick={onStatusClick} />}
        />
      </Routes>
    </AppPage>
  );
};
