import { setupWorker } from 'msw';

import { createHandlers } from './handlers';

export const startWorker = () => {
  const handlers = createHandlers();

  if (handlers.length === 0) {
    return Promise.resolve();
  }

  return setupWorker(...handlers).start();
};
