import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { useApi } from '../../../api/ApiProvider';
import { ApiResponse } from '../../../api/types';

type UseDeleteIncidentMutationParams = { incidentId: string };

export const useDeleteIncidentMutation = (
  opts?: UseMutationOptions<
    ApiResponse,
    AxiosError,
    UseDeleteIncidentMutationParams
  >,
) => {
  const api = useApi();

  const mutation = useMutation<
    ApiResponse,
    AxiosError,
    UseDeleteIncidentMutationParams
  >(async ({ incidentId }) => {
    const { data } = await api.delete(`/incident/${incidentId}`);

    return data;
  }, opts);

  return mutation;
};
