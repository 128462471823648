import {
  FormControl,
  Box,
  FormLabel,
  HStack,
  Text,
  BoxProps,
} from '@chakra-ui/react';
import { FC, ReactElement } from 'react';

export type StatisticSummaryProps = {
  iconLeft: ReactElement;
  summary: string;
  label: string;
} & BoxProps;

export const StatisticSummary: FC<StatisticSummaryProps> = (props) => {
  const { iconLeft, summary, label, ...boxProps } = props;

  return (
    <FormControl as={Box} {...boxProps}>
      <FormLabel
        as="legend"
        whiteSpace="nowrap"
        fontSize="xs"
        fontWeight="normal"
        color="#414042"
      >
        {label}
      </FormLabel>

      <HStack border="1px solid #e6e7e8" padding={3}>
        {iconLeft}
        <Text whiteSpace="nowrap" fontSize="xs">
          {summary}
        </Text>
      </HStack>
    </FormControl>
  );
};
