import { context } from 'msw';

export const response = () => {
  return context.json({
    id: 1,
    startTime: '2022-05-21T22:45:00',
    endTime: null,
    countDowntime: true,
    totalDowntime: 4499000,
    summary: 'Summary #1',
    rootCause: 'Root cause #1',
    remediation: 'Remediation #1',
    impact: 'Impact #1',
    newsletterSent: Date.now() % 2 === 0,
    jiraIssueURL: 'Jira issue URL #1',
    active: true,
  });
};
