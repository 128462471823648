import { createIcon } from '@chakra-ui/react';

export const MagnifierIcon = createIcon({
  displayName: 'MagnifierIcon',
  viewBox: '0 0 36 36',
  path: [
    <path
      d="M8.5,-0.8 C3.36375183,-0.8 -0.8,3.36375183 -0.8,8.5 C-0.8,13.6362482 3.36375183,17.8 8.5,17.8 C13.6362482,17.8 17.8,13.6362482 17.8,8.5 C17.8,3.36375183 13.6362482,-0.8 8.5,-0.8 Z M8.5,0.8 C12.7525926,0.8 16.2,4.24740743 16.2,8.5 C16.2,12.7525926 12.7525926,16.2 8.5,16.2 C4.24740743,16.2 0.8,12.7525926 0.8,8.5 C0.8,4.24740743 4.24740743,0.8 8.5,0.8 Z"
      id="Oval-2"
    />,
    <path
      d="M15.9696699,15.9696699 C16.2359365,15.7034034 16.6526002,15.6791973 16.9462117,15.8970518 L17.0303301,15.9696699 L24.0303301,22.9696699 C24.3232233,23.2625631 24.3232233,23.7374369 24.0303301,24.0303301 C23.7640635,24.2965966 23.3473998,24.3208027 23.0537883,24.1029482 L22.9696699,24.0303301 L15.9696699,17.0303301 C15.6767767,16.7374369 15.6767767,16.2625631 15.9696699,15.9696699 Z"
      id="Line"
    />,
  ],
});
