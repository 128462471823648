import { createIcon } from '@chakra-ui/react';

export const OngoingIncidentsIcon = createIcon({
  viewBox: '0 0 68 68',
  defaultProps: {
    width: '20',
    height: 'auto',
  },
  path: [
    <rect
      id="XMLID_2018_"
      x="0"
      y="0"
      style={{ fill: 'none' }}
      width="68"
      height="68"
    />,
    <path
      style={{ fill: '#414042' }}
      d="M55.986,55.997H12.014c-1.085,0-2.056-0.561-2.599-1.5s-0.542-2.062,0-3.001l21.986-38.081    c0.542-0.939,1.514-1.5,2.599-1.5c1.085,0,2.056,0.561,2.599,1.5l21.986,38.081c0.542,0.94,0.542,2.063-0.001,3.001    C58.042,55.437,57.07,55.997,55.986,55.997z M34,13.915c-0.174,0-0.606,0.049-0.866,0.5L11.147,52.496    c-0.261,0.451-0.087,0.851,0,1.001s0.345,0.5,0.867,0.5h43.972c0.521,0,0.779-0.35,0.866-0.5s0.261-0.549,0-1.001L34.866,14.415    C34.606,13.964,34.174,13.915,34,13.915z"
    />,
    <circle
      id="XMLID_2016_"
      style={{ fill: '#DC0032' }}
      cx="34"
      cy="47.144"
      r="1"
    />,
    <path
      style={{ fill: '#DC0032' }}
      d="M34,44.144c-0.552,0-1-0.447-1-1V28.957c0-0.552,0.448-1,1-1s1,0.448,1,1v14.187    C35,43.696,34.552,44.144,34,44.144z"
    />,
  ],
});
