import { createIcon } from '@chakra-ui/react';

export const ServiceCheckedIcon = createIcon({
  viewBox: '0 0 68 68',
  defaultProps: {
    width: '20',
    height: 'auto',
  },
  path: [
    <style type="text/css">
      {`.st0{fill:none;stroke:#414042;stroke-width:2;stroke-linecap:round;stroke-miterlimit:10;}
    .st1{fill:#414042;}
    .st2{fill:none;}
    .st3{fill:#DC0032;}
    .st4{fill:none;stroke:#231F20;stroke-linecap:round;stroke-miterlimit:10;}
    .st5{fill:none;stroke:#414042;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
    .st6{fill:none;stroke:#DC0032;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
    .st7{fill:none;stroke:#DC0032;stroke-width:2;stroke-miterlimit:10;}`}
    </style>,
    <path
      className="st1"
      d="M49,50H19c-0.553,0-1-0.447-1-1v-8c0-0.553,0.447-1,1-1h30c0.553,0,1,0.447,1,1v8C50,49.553,49.552,50,49,50z    M20,48h28v-6H20V48z"
    />,
    <circle className="st3" cx="23" cy="45" r="1" />,
    <path
      className="st3"
      d="M45,46h-4c-0.553,0-1-0.447-1-1s0.447-1,1-1h4c0.553,0,1,0.447,1,1S45.552,46,45,46z"
    />,
    <path
      className="st1"
      d="M49,38H19c-0.553,0-1-0.447-1-1v-8c0-0.553,0.447-1,1-1h30c0.553,0,1,0.447,1,1v8C50,37.553,49.552,38,49,38z    M20,36h28v-6H20V36z"
    />,
    <circle className="st3" cx="23" cy="33" r="1" />,
    <path
      className="st3"
      d="M45,34h-4c-0.553,0-1-0.447-1-1s0.447-1,1-1h4c0.553,0,1,0.447,1,1S45.552,34,45,34z"
    />,
    <path
      className="st1"
      d="M49,26H19c-0.553,0-1-0.447-1-1v-8c0-0.553,0.447-1,1-1h30c0.553,0,1,0.447,1,1v8C50,25.553,49.552,26,49,26z    M20,24h28v-6H20V24z"
    />,
    <circle className="st3" cx="23" cy="21" r="1" />,
    <path
      className="st3"
      d="M45,22h-4c-0.553,0-1-0.447-1-1s0.447-1,1-1h4c0.553,0,1,0.447,1,1S45.552,22,45,22z"
    />,
  ],
});
