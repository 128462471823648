import { Image, ImageProps } from '@chakra-ui/react';
import { FC } from 'react';

export type GeopostIconFullProps = ImageProps;

export const GeopostIcon: FC<GeopostIconFullProps> = (props) => {
  return (
    <Image src={process.env.PUBLIC_URL + '/geopost_icon.svg'} {...props} />
  );
};
