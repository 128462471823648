import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useApi } from '../../api/ApiProvider';
import { ApiResponse } from '../../api/types';

type TicketManagementAvailableMonth = {
  month: number;
  year: number;
};

export type TicketManagementAvailableMonthsData =
  TicketManagementAvailableMonth[];

type QueryResult = ApiResponse<TicketManagementAvailableMonthsData>;

export const useTicketManagementAvailableMonthsQuery = (
  opts?: UseQueryOptions<QueryResult, AxiosError>,
) => {
  const api = useApi();

  const queryFn = async ({ signal }: { signal?: AbortSignal }) => {
    const { data } = await api.get<QueryResult>(
      `/ticket/management/month-list`,
      {
        signal,
      },
    );
    return data;
  };

  const query = useQuery<QueryResult, AxiosError>(
    ['ticketManagement', 'available-months'],
    queryFn,
    opts,
  );

  return query;
};
