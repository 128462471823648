import { createContext, FC, useCallback, useContext, useMemo } from 'react';
import { Navigate } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { useAuth } from './AuthProvider';

const PERMISSIONS_SCHEME = {
  ADMIN: [
    'ApplicationMonitor',
    'TicketManagement',
    'TicketStatistic',
    'Configuration',
  ],
  GSA: ['ApplicationMonitor', 'TicketManagement', 'TicketStatistic'],
  BU: ['ApplicationMonitor', 'TicketStatistic'],
  USER: ['ApplicationMonitor', 'TicketStatistic'],
  TV: [],
  GRAFANA: [],
} as const;

type Permissions = typeof PERMISSIONS_SCHEME;
type Resource = Permissions[keyof Permissions][number];
type Scope = keyof Permissions;
interface PermissionsContextState {
  hasAccess?(path: Resource): boolean;
  isAdmin?: boolean;
  fallbackRoute?: string;
}

const defaultValue: PermissionsContextState = {};
const PermissionsContext = createContext<PermissionsContextState>(defaultValue);

interface PermissionsProviderProps {
  fallbackRoute: string;
}

export const PermissionsProvider: FC<
  React.PropsWithChildren<PermissionsProviderProps>
> = ({ fallbackRoute, children }) => {
  const auth = useAuth();

  const hasAccess = useCallback(
    (key: Resource) =>
      auth.state === 'signedIn' &&
      (auth.scopes as Scope[]).some(
        (scope) =>
          PERMISSIONS_SCHEME[scope].filter((resource) => resource === key)
            .length > 0,
      ),
    [auth],
  );

  const isAdmin = useMemo(
    () =>
      auth.state === 'signedIn' &&
      auth.scopes.map((scope) => scope.toLocaleUpperCase()).includes('ADMIN'),
    [auth],
  );

  const state: PermissionsContextState = {
    hasAccess,
    isAdmin,
    fallbackRoute,
  };

  return (
    <PermissionsContext.Provider value={state}>
      {children}
    </PermissionsContext.Provider>
  );
};

interface ProtectedResourceProps {
  permissionsFor: Resource;
  shouldRedirect?: boolean;
}

export const ProtectedResource: FC<
  React.PropsWithChildren<ProtectedResourceProps>
> = ({ permissionsFor, children, shouldRedirect }) => {
  const { state } = useAuth();
  const { hasAccess, fallbackRoute } = usePermissions();

  if (state === 'signedOut') {
    return null;
  }

  const isProtected = !hasAccess?.(permissionsFor);

  if (isProtected) {
    return shouldRedirect ? <Navigate to={fallbackRoute as string} /> : null;
  }

  return <>{children}</>;
};

export const usePermissions = () => {
  const permissions = useContext(PermissionsContext);

  invariant(
    permissions !== defaultValue,
    `"usePermissions" hook used outside of its' provider`,
  );

  return permissions;
};
