import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Center,
  Heading,
  HStack,
  Spinner,
  Stack,
  Text,
  Wrap,
} from '@chakra-ui/react';
import { FC, useMemo } from 'react';
import { BsExclamationLg } from 'react-icons/bs';
import { useSearchParams } from 'react-router-dom';
import { ErrorIcon } from '../../icons/ErrorIcon';
import { InfoIcon } from '../../icons/InfoIcon';
import { SuccessIcon } from '../../icons/SuccessIcon';
import { StatisticSummary } from '../../ui-components/StatisticSummary';
import { ApplicationMonitorTable } from './ApplicationMonitorTable';
import { PeriodType } from './types';
import {
  isViewType,
  useMonitorTableDataQuery,
  ViewType,
} from './useMonitorTableDataQuery';

import { FormControl, FormLabel, Select, SelectProps } from '@dpd-ui/core';

export type AppsOverviewProps = {
  onStatusClick: (args: {
    statusId: string;
    appId: string;
    period: string;
    periodType: PeriodType;
  }) => void;
};

const viewOptions: { label: string; value: ViewType }[] = [
  {
    value: 'last-thirty-days',
    label: 'Last 30 days',
  },
  {
    value: 'all-time',
    label: 'All time',
  },
  {
    value: 'last-twelve-months',
    label: 'Last 12 months',
  },
];

export const AppsOverview: FC<AppsOverviewProps> = (props) => {
  const { onStatusClick } = props;

  const defaultViewType = viewOptions[0].value;
  const [searchParams, setSearchParams] = useSearchParams({
    viewType: defaultViewType,
  });

  const parsed = Object.fromEntries(searchParams);
  const { viewType, selectedAppId } = parsed;

  const selectedViewType = isViewType(viewType) ? viewType : defaultViewType;
  const { isLoading, isError, data, error } = useMonitorTableDataQuery({
    viewType: selectedViewType,
  });

  const mergeSearchParams = (obj: typeof parsed) => {
    setSearchParams({
      ...parsed,
      ...obj,
    });
  };

  const tableData = useMemo(() => {
    if (!data) {
      return null;
    }

    if (!selectedAppId) {
      return data;
    }

    return {
      ...data,
      applications: Object.fromEntries(
        Object.entries(data.applications).filter(
          ([appId]) => appId === selectedAppId,
        ),
      ),
    };
  }, [selectedAppId, data]);

  if (isLoading) {
    return (
      <Center height="full" width="full">
        <Spinner />
      </Center>
    );
  }

  if (isError) {
    return (
      <Center height="full" width="full">
        <Alert
          status="error"
          as={Stack}
          width="90%"
          maxWidth="400px"
          borderRadius="lg"
          border="1px solid"
          borderColor="red.300"
        >
          <HStack>
            <AlertIcon />
            <Text>Error: {(error as Error)?.message ?? 'Smth went wrong'}</Text>
          </HStack>
          <Button onClick={() => window.location.reload()}>
            Refresh the page
          </Button>
        </Alert>
      </Center>
    );
  }

  const { applications } = data!;

  const renderStats = () => {
    const displayedApps = Object.values(tableData!.applications);
    const totalDisplayedIncidents = displayedApps.reduce(
      (sum, { numberOfIncidents }) => sum + numberOfIncidents,
      0,
    );

    const incidentsSummaryIcon =
      totalDisplayedIncidents === 0 ? (
        <SuccessIcon fill="#509e2f" boxSize="6" />
      ) : (
        <InfoIcon fill="#ffc004" boxSize="6" />
      );

    return (
      <StatisticSummary
        flex={1}
        cursor="default"
        iconLeft={incidentsSummaryIcon}
        label="Incidents summary"
        summary={`${totalDisplayedIncidents} incident${
          totalDisplayedIncidents === 1 ? '' : 's'
        }`}
      />
    );
  };

  const renderAppsFilterInput = () => {
    const options: SelectProps['options'] = [
      ...Object.entries(applications)
        .sort(([, { name: a }], [, { name: b }]) => {
          if (a.toLowerCase() < b.toLowerCase()) return -1;
          if (a.toLowerCase() > b.toLowerCase()) return 1;
          return 0;
        })
        .map(([appId, { name }]) => ({
          label: name,
          value: appId,
        })),
    ];

    return (
      <FormControl flex={1}>
        <Select
          options={options}
          onChange={(value) => {
            mergeSearchParams({
              selectedAppId: value ?? '',
            });
          }}
          value={selectedAppId ?? ''}
        />
        <FormLabel>Choose applications</FormLabel>
      </FormControl>
    );
  };

  const renderPeriodsInput = () => {
    return (
      <FormControl flex={1}>
        <Select
          options={viewOptions}
          onChange={(value) =>
            mergeSearchParams({
              viewType: value as ViewType,
            })
          }
          value={selectedViewType}
        />
        <FormLabel>Switch view</FormLabel>
      </FormControl>
    );
  };

  const renderLegend = () => {
    return (
      <HStack spacing={5} paddingTop={3}>
        <HStack alignItems="center">
          <SuccessIcon fill="green" boxSize={4} alignItems="center" />
          <Text fontSize="xs">Service working normally</Text>
        </HStack>

        <HStack alignItems="center">
          <Box position="relative">
            <SuccessIcon boxSize={4} fill="green" />
            <Box
              as="span"
              position="absolute"
              top={0}
              right={0}
              transform="auto"
              translateX="60%"
              translateY="-50%"
            >
              <BsExclamationLg size={12} />
            </Box>
          </Box>
          <Text fontSize="xs">Incident with no downtime</Text>
        </HStack>

        <HStack>
          <InfoIcon fill="#ffc004" boxSize={4} />
          <Text fontSize="xs">Service disruption</Text>
        </HStack>

        <HStack>
          <ErrorIcon fill="#dc0032" />
          <Text fontSize="xs">Service outage</Text>
        </HStack>

        <HStack>
          <Text>N</Text>
          <Text fontSize="xs">Newsletter sent</Text>
        </HStack>
      </HStack>
    );
  };

  return (
    <Stack flex={1} padding={5} spacing={6} width={0}>
      <Heading
        fontWeight="light"
        color="rgba(65, 64, 66, 1)"
        fontSize="1.325rem"
        paddingBottom={5}
      >
        Applications Availability Monitor
      </Heading>

      <Wrap spacing={5} align="flex-end" maxWidth="1000px" overflow={'unset'}>
        {renderStats()}
        {renderAppsFilterInput()}
        {renderPeriodsInput()}
      </Wrap>

      <Stack flex={1} height={0} overflow="hidden">
        <ApplicationMonitorTable
          data={tableData!}
          onStatusClick={onStatusClick}
          tableProps={{ flex: 1 }}
        />
        {renderLegend()}
      </Stack>
    </Stack>
  );
};
