import { createIcon } from '@chakra-ui/react';

export const WorkingNormallyIcon = createIcon({
  viewBox: '0 0 68 68',
  defaultProps: {
    width: '20',
    height: 'auto',
  },
  path: [
    <path
      style={{
        fill: '#414042',
      }}
      d="M49.009,49c-0.553,0-1-0.447-1-1s0.447-1,1-1c0.328,0,0.582-0.098,0.752-0.289     c0.157-0.177,0.247-0.437,0.247-0.711c0-0.167-0.048-1-0.999-1c-0.553,0-1-0.447-1-1s0.447-1,1-1c1.969,0,2.999,1.509,2.999,3     c0,0.77-0.267,1.493-0.752,2.039C50.705,48.659,49.907,49,49.009,49z"
    />,
    <path
      style={{
        fill: '#414042',
      }}
      d="M50.008,45h-0.999c-0.553,0-1-0.447-1-1s0.447-1,1-1h0.999c0.329,0,0.582-0.098,0.753-0.289     c0.157-0.177,0.247-0.437,0.247-0.711c0-0.167-0.048-1-1-1c-0.553,0-1-0.447-1-1s0.447-1,1-1c1.97,0,3,1.509,3,3     c0,0.77-0.267,1.493-0.753,2.039C51.705,44.659,50.906,45,50.008,45z"
    />,
    <path
      style={{
        fill: '#414042',
      }}
      d="M50.008,41c-0.553,0-1-0.447-1-1s0.447-1,1-1c0.329,0,0.582-0.098,0.753-0.289     c0.157-0.177,0.247-0.437,0.247-0.711c0-0.167-0.048-1-1-1v-2c1.97,0,3,1.509,3,3c0,0.77-0.267,1.493-0.753,2.039     C51.705,40.659,50.906,41,50.008,41z"
    />,
    <path
      style={{
        fill: '#414042',
      }}
      d="M49.009,49H38.761c-6.408,0-9.752-0.416-9.752-9.359c0-2.61,0-5.57,2.366-7.427     c0.656-0.515,1.49-0.998,2.374-1.508c1.556-0.9,3.319-1.92,4.49-3.341c2.905-3.53,5.202-7.124,5.225-7.16     c1.412-1.979,3.334-2.116,4.535-1.427c1.213,0.698,2.068,2.446,1.081,4.634l-3.127,8.92c-0.184,0.521-0.757,0.794-1.274,0.612     c-0.521-0.183-0.796-0.753-0.612-1.274l3.159-9c0.51-1.139,0.247-1.886-0.225-2.158c-0.459-0.266-1.22-0.114-1.88,0.811     c-0.065,0.106-2.352,3.686-5.338,7.313c-1.4,1.7-3.33,2.816-5.032,3.8c-0.82,0.475-1.595,0.923-2.14,1.351     c-1.601,1.256-1.601,3.387-1.601,5.854C31.008,47,32.806,47,38.761,47h10.248c0.553,0,1,0.447,1,1S49.562,49,49.009,49z"
    />,
    <path
      style={{
        fill: '#414042',
      }}
      d="M50.009,37c-0.553,0-1-0.447-1-1s0.447-1,1-1c0.328,0,0.582-0.097,0.752-0.289     c0.157-0.177,0.247-0.436,0.247-0.711c0-0.167-0.048-1-0.999-1h-5.001c-0.553,0-1-0.448-1-1s0.447-1,1-1h5.001     c1.969,0,2.999,1.509,2.999,3c0,0.769-0.267,1.493-0.752,2.039C51.705,36.659,50.907,37,50.009,37z"
    />,
    <path
      style={{
        fill: '#DC0032',
      }}
      d="M27.024,50.032h-6.565c-0.57,0-1.032-0.462-1.032-1.032s0.462-1.032,1.032-1.032h4.501V32.032     h-7.936V49c0,0.57-0.462,1.032-1.032,1.032S14.959,49.57,14.959,49V29.968h12.064V50.032z"
    />,
  ],
});
