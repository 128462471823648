import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Table,
  TableProps,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { format } from 'date-fns';
import React, { FC, Fragment, useMemo } from 'react';
import {
  Cell,
  Column,
  Row,
  useBlockLayout,
  useGroupBy,
  useSortBy,
  useTable,
} from 'react-table';
import { useSticky } from 'react-table-sticky';
import { SuccessIcon } from '../../icons/SuccessIcon';
import { convertMsToHM } from '../../utils/timeHelpers';
import { PeriodType } from './types';

import { MonitorTableData } from './useMonitorTableDataQuery';
import { BsExclamationLg } from 'react-icons/bs';
import { ErrorIcon } from '../../icons/ErrorIcon';
import { InfoIcon } from '../../icons/InfoIcon';
import { UnknownIcon } from '../../icons/UnknownIcon';
import { calculateSlaColor } from '../../utils/slaHelpers';

export type ApplicationMonitorTableProps = {
  data: MonitorTableData;
  onStatusClick: (args: {
    statusId: string;
    appId: string;
    period: string;
    periodType: PeriodType;
  }) => void;
  tableProps?: TableProps;
};

export const ApplicationMonitorTable: FC<ApplicationMonitorTableProps> = (
  props
) => {
  const { data: tableData, onStatusClick } = props;

  const columns = useMemo<(Column & { sticky?: 'left' | 'right' })[]>(
    () => [
      {
        Header: 'Application',
        accessor: 'applicationName',
        sticky: 'left',
        minWidth: 200,
        Cell: ({ value }) => (
          <Box paddingX={6} paddingY={4}>
            {value}
          </Box>
        ),
      },
      {
        Header: 'Uptime',
        accessor: 'totalUptimePercent',
        sticky: 'left',
        Cell: ({ value }) => (
          <Box
            paddingX={6}
            paddingY={4}
            color={calculateSlaColor(value)}
          >{`${value}%`}</Box>
        ),
      },
      {
        Header: 'Downtime',
        accessor: 'totalDowntimeMillis',
        sticky: 'left',
        maxWidth: 100,
        Cell: ({ value }) => {
          const parsed = parseInt(value, 10);

          const renderDisplayValue = () => {
            if (Number.isNaN(parsed)) {
              return '[parsing error]';
            }

            if (parsed === 0) {
              return '0m 0s';
            }

            return convertMsToHM(parsed);
          };

          return (
            <Box paddingX={6} paddingY={4}>
              {renderDisplayValue()}
            </Box>
          );
        },
      },

      { Header: 'appGroupId', accessor: 'appGroupId' },

      ...Object.entries(tableData.periods.items)
        .sort(([, { value: a }], [, { value: b }]) => {
          if (a === b) {
            return 0;
          }

          return a < b ? 1 : -1;
        })
        .map(([periodId, period]): Column => {
          return {
            Header: () => {
              const renderFormattedValue = () => {
                const periodValue = period.value;
                const periodsType = tableData.periods.type;

                if (periodsType === 'DAYS') {
                  return format(new Date(periodValue), 'MMM dd');
                }

                if (periodsType === 'MONTHS') {
                  return format(new Date(periodValue), 'MMM yyyy');
                }

                if (periodsType === 'YEARS') {
                  return format(new Date(periodValue), 'yyyy');
                }

                return format(new Date(periodValue), 'MMM dd yyyy');
              };

              return <>{renderFormattedValue()}</>;
            },
            accessor: `period.${periodId}`,
            width: 75,
            Cell: ({ value, row }) => {
              if ((row as unknown as Record<'isGrouped', boolean>).isGrouped) {
                return <></>;
              }

              const { status, statusId, appId, periodId, newsletterSent } =
                value ?? {};

              const renderIcon = () => {
                return (
                  <Box position="relative">
                    {status === 'OK' && (
                      <SuccessIcon
                        boxSize={5}
                        fill="green"
                        _hover={{ transform: 'scale(1.5)' }}
                      />
                    )}
                    {status === 'OK_WITH_INFORMATION' && (
                      <Flex>
                        <SuccessIcon
                          boxSize={5}
                          fill="green"
                          _hover={{ transform: 'scale(1.5)' }}
                        />
                        <Box
                          as="span"
                          position="absolute"
                          top={0}
                          right={0}
                          transform="auto"
                          translateX="90%"
                          translateY="-50%"
                        >
                          <BsExclamationLg size={12} />
                        </Box>
                      </Flex>
                    )}
                    {status === 'OUTAGE' && (
                      <ErrorIcon
                        boxSize={5}
                        fill="#dc0032"
                        _hover={{ transform: 'scale(1.5)' }}
                      />
                    )}
                    {status === 'DISRUPTION' && (
                      <InfoIcon
                        boxSize={5}
                        fill="#ffc004"
                        _hover={{ transform: 'scale(1.5)' }}
                      />
                    )}
                    {(!status || status === 'UNKNOWN') && (
                      <UnknownIcon
                        fill="#808285"
                        boxSize={5}
                        _hover={{ transform: 'scale(1.5)' }}
                      />
                    )}
                    {status && status !== 'UNKNOWN' && newsletterSent && (
                      <Box
                        as="span"
                        position="absolute"
                        top={0}
                        right={0}
                        transform="auto"
                        translateX="100%"
                        translateY="+50%"
                      >
                        <Text fontSize="xs">N</Text>
                      </Box>
                    )}
                  </Box>
                );
              };

              return (
                <Button
                  variant="unstyled"
                  width="full"
                  height="full"
                  onClick={() => {
                    const period = tableData.periods.items[periodId].value;

                    onStatusClick({
                      statusId,
                      appId,
                      period,
                      periodType:
                        tableData.periods.type === 'DAYS' ? 'day' : 'month',
                    });
                  }}
                >
                  <Center paddingX={6} paddingY={4} cursor="pointer">
                    {renderIcon()}
                  </Center>
                </Button>
              );
            },
          };
        }),
    ],
    [onStatusClick, tableData.periods.items, tableData.periods.type]
  );

  const data = useMemo(
    () =>
      Object.entries(tableData.applications).map(([appId, app]) => {
        return {
          ...app,
          applicationName: app.name,
          appGroupId: app.applicationGroupId,
          period: Object.entries(tableData.statuses).reduce(
            (acc: {}, [statusId, status]) => {
              if (status.applicationId !== appId) return acc;

              return {
                ...acc,
                [status.periodId]: { ...status, statusId, appId },
              };
            },
            {}
          ),
        };
      }),
    [tableData.applications, tableData.statuses]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        initialState: {
          groupBy: ['appGroupId'],
          hiddenColumns: ['appGroupId'],
        } as any,
      },
      useGroupBy,
      useSortBy,
      useBlockLayout,
      useSticky
    );

  const renderHead = () => {
    const headerBgColor = '#414042';
    const [headerGroup] = headerGroups;

    const [appNameHeader, uptimeHeader, downtimeHeader, ...dynamicHeaders] =
      headerGroup.headers as any[];
    const headerGroupProps = headerGroup.getHeaderGroupProps();

    return (
      <Thead position="sticky" top={0} zIndex={5}>
        <Tr {...headerGroupProps} minWidth="100%" height="35px">
          <Th
            color={'white'}
            background={headerBgColor}
            textTransform="none"
            display="flex"
            {...appNameHeader.getHeaderProps(
              appNameHeader.getSortByToggleProps()
            )}
          >
            <HStack height="full">
              <Text fontWeight="normal" fontSize="s">
                {appNameHeader.render('Header')}
              </Text>
              <Box as="span" pl="4">
                {appNameHeader.isSorted ? (
                  appNameHeader.isSortedDesc ? (
                    <Text transform="rotateZ(180deg)">^</Text>
                  ) : (
                    <Text>^</Text>
                  )
                ) : null}
              </Box>
            </HStack>
          </Th>

          <Th
            color={'white'}
            background={headerBgColor}
            textTransform="none"
            {...uptimeHeader.getHeaderProps(
              uptimeHeader.getSortByToggleProps()
            )}
          >
            <HStack height="full">
              <Text fontWeight="normal" fontSize="s">
                {uptimeHeader.render('Header')}
              </Text>
              <Box as="span" pl="4">
                {uptimeHeader.isSorted ? (
                  uptimeHeader.isSortedDesc ? (
                    <Text transform="rotateZ(180deg)">^</Text>
                  ) : (
                    <Text>^</Text>
                  )
                ) : null}
              </Box>
            </HStack>
          </Th>

          <Th
            color={'white'}
            background={headerBgColor}
            borderRight="1px solid white"
            textTransform="none"
            display="flex"
            {...downtimeHeader.getHeaderProps(
              downtimeHeader.getSortByToggleProps()
            )}
          >
            <HStack height="full">
              <Text fontWeight="normal" fontSize="s">
                {downtimeHeader.render('Header')}
              </Text>
              <Box as="span" pl="4">
                {downtimeHeader.isSorted ? (
                  downtimeHeader.isSortedDesc ? (
                    <Text transform="rotateZ(180deg)">^</Text>
                  ) : (
                    <Text>^</Text>
                  )
                ) : null}
              </Box>
            </HStack>
          </Th>

          {dynamicHeaders.map((column) => (
            <Th
              color={'white'}
              fontWeight="medium"
              background={headerBgColor}
              textTransform="none"
              flex={1}
              {...column.getHeaderProps(column.getSortByToggleProps())}
              style={{ ...column.getHeaderProps.style, display: 'flex' }}
              padding="0"
              alignItems="center"
              justifyContent="center"
            >
              <Center height="full">
                <Text fontWeight="normal" fontSize="s">
                  {column.render('Header')}

                  {column.isSorted ? (
                    column.isSortedDesc ? (
                      <>&nbsp;^</>
                    ) : (
                      <>&nbsp;⌄</>
                    )
                  ) : null}
                </Text>
              </Center>
            </Th>
          ))}
        </Tr>
      </Thead>
    );
  };

  const renderBody = () => {
    const renderGroupingRow = (row: Row) => {
      const groupColor = '#808285';

      const { values, cells } = row;
      const [appNameCell, appUptimeCell, appDownTimeCell, ...dynamicCells] =
        cells;

      const appNameProps = appNameCell.getCellProps();
      const uptimeProps = appUptimeCell.getCellProps();
      const downtimeProps = appDownTimeCell.getCellProps();

      const appNameGroupingCellWidth = `calc(${appNameProps.style?.width} + ${uptimeProps.style?.width} + ${downtimeProps.style?.width})`;

      const rowProps = row.getRowProps();

      const groupName = Object.entries(tableData.applicationGroups).find(
        ([groupId]) => groupId === values.appGroupId
      )?.[1]?.name;

      if (!groupName) {
        return null;
      }

      return (
        <Box
          key={rowProps.key}
          height="25px"
          boxShadow="lg"
          position="relative"
          bg="#e6e7e8"
          color={groupColor}
          minWidth="100%"
          zIndex={4}
        >
          <Tr {...rowProps} height="full">
            <Td
              {...appNameProps}
              style={{
                ...appNameProps.style,
                width: appNameGroupingCellWidth,
                display: 'flex',
              }}
              position="sticky"
              left={0}
              color={groupColor}
              borderRight="1px solid white"
              colSpan={3}
              padding={0}
              paddingLeft={6}
              alignItems="center"
              fontSize="0.8125rem"
              borderBottom="none"
            >
              {groupName ?? 'Unknown group'}
            </Td>

            {dynamicCells.slice(3).map((cell: Cell) => (
              <Td {...cell.getCellProps()}>{cell.render('Cell')}</Td>
            ))}
          </Tr>
        </Box>
      );
    };

    const renderLeafRows = (row: any) => {
      return row.leafRows.map((leafRow: any) => {
        prepareRow(leafRow);

        const rowProps = leafRow.getRowProps();

        return (
          <Tr
            {...rowProps}
            minWidth="100%"
            borderBottom="1px solid"
            borderColor="#ccc"
            height="45px"
          >
            {leafRow.cells.map((cell: any) => {
              const cellProps = cell.getCellProps();
              return (
                <Td
                  {...cellProps}
                  bg="white"
                  padding={0}
                  whiteSpace="nowrap"
                  sx={{
                    flex: 1,
                    '&[data-sticky-td]': {
                      flex: 'unset',
                    },
                  }}
                  fontSize="xs"
                  color="#414042"
                >
                  {cell.render('Cell')}
                </Td>
              );
            })}
          </Tr>
        );
      });
    };

    return (
      <Tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);

          return (
            <Fragment key={row.getRowProps().key}>
              {renderGroupingRow(row)}
              {renderLeafRows(row)}
            </Fragment>
          );
        })}
      </Tbody>
    );
  };

  return (
    <Box
      width="full"
      overflowX="auto"
      border="1px solid #ccc"
      sx={{
        '[data-sticky-td]': {
          position: 'sticky',
        },
      }}
    >
      <Table {...getTableProps()}>
        {renderHead()}

        {renderBody()}
      </Table>
    </Box>
  );
};
