import { FC } from 'react';
import { HStack, Box } from '@chakra-ui/react';

interface TileProps {
  icon: React.ReactNode;
  value: string;
  label: string;
}

export const Tile: FC<TileProps> = ({ icon, value, label }) => (
  <HStack width="420px" height="70px">
    <Box paddingInlineStart={5} paddingInlineEnd={10} paddingBlock="10px">
      {icon}
    </Box>
    <Box
      fontSize="28px"
      whiteSpace="nowrap"
      lineHeight="initial"
      marginInlineStart={0}
    >
      {value}
    </Box>
    <Box
      flex={2}
      color="dpdGray.500"
      fontSize="18px"
      lineHeight="initial"
      paddingInlineStart={10}
    >
      {label}
    </Box>
  </HStack>
);
