import { context } from 'msw';
import { appGroupNames, appNames, colors } from '../data';

export const response = () =>
  context.json(
    appNames.map((name, index) => ({
      id: String(index),
      appGroupId: String(index % appGroupNames.length),
      name,
      // TODO: confirm this api
      color: colors[index % colors.length],
    })),
  );
