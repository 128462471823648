import { useCallback } from 'react';
import { AxiosError } from 'axios';
import {
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';
import { useApi } from '../../../api/ApiProvider';
import { IncidentDetail } from './types';
import { ApiResponse } from '../../../api/types';
import { ISODate } from '../../../types';

export type IncidentDetails = IncidentDetail[];

type QueryResult = ApiResponse<IncidentDetails>;

type UseIncidentDetailsQueryParams = {
  appId: string;
  periodValue: ISODate;
};

export const createIncidentDetailsCacheKey = (
  params: UseIncidentDetailsQueryParams,
) => ['incidentDetails', params];

export const useIncidentDetailsQuery = (
  params: UseIncidentDetailsQueryParams,
  opts?: UseQueryOptions<QueryResult, AxiosError>,
) => {
  const api = useApi();

  const { appId, periodValue } = params;

  const queryFn = async ({ signal }: { signal?: AbortSignal }) => {
    const { data } = await api.get<QueryResult>(
      `/ticket/incident/application/${appId}?date=${periodValue}`,
      { signal },
    );

    return data;
  };

  const query = useQuery<QueryResult, AxiosError>(
    createIncidentDetailsCacheKey(params),
    queryFn,
    opts,
  );

  return query;
};

export const useInvalidateIncidentDetailsQuery = (
  params: UseIncidentDetailsQueryParams,
) => {
  const queryClient = useQueryClient();

  const invalidate = useCallback(
    () => queryClient.invalidateQueries(createIncidentDetailsCacheKey(params)),
    [params, queryClient],
  );

  return invalidate;
};
