import {
  Flex,
  HStack,
  Link,
  Table,
  TableProps,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { format } from 'date-fns';
import { FC, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Column, useSortBy, useTable } from 'react-table';
import { ConfigIcon } from '../../../icons/ConfigIcon';
import { PencilIcon } from '../../../icons/PencilIcon';
import { SuccessIcon } from '../../../icons/SuccessIcon';
import { TrashIcon } from '../../../icons/TrashIcon';
import { usePermissions } from '../../../providers/PermissionProvider';

import { convertMsToHM } from '../../../utils/timeHelpers';
import { createIncidentConfigPath } from '../../ConfigurationPage';

import { Id, IncidentDetail } from '../types';

export type IncidentsTableProps = {
  incidents: { [key in Id]: IncidentDetail };
  appId: string;
  onDeleteIncident: (incidentId: string) => void;
} & TableProps;

export const IncidentsTable: FC<IncidentsTableProps> = (props) => {
  const { appId, incidents, onDeleteIncident, ...tableProps } = props;
  const { isAdmin } = usePermissions();

  const navigate = useNavigate();

  const navigateToIncidentConfig = useCallback(
    (newParams: string) => {
      navigate(
        '/configuration' +
          createIncidentConfigPath({
            appIdSegment: appId,
            incidentIdSegment: newParams,
          }),
      );
    },
    [appId, navigate],
  );

  const columns: Column[] = useMemo(
    () =>
      (
        [
          {
            Header: 'Start date and time',
            accessor: 'startTime',
            Cell: ({ value }) => (
              <Text whiteSpace="nowrap">
                {format(new Date(value), 'dd.MM.yyyy, H:mm:ss')}
              </Text>
            ),
          },
          {
            Header: 'End date and time',
            accessor: 'endTime',
            Cell: ({ value }) => (
              <Text whiteSpace="nowrap">
                {value && format(new Date(value), 'dd.MM.yyyy, H:mm:ss')}
              </Text>
            ),
          },
          {
            Header: 'Total downtime',
            accessor: 'totalDowntime',
            Cell: ({ value }) => <Text>{convertMsToHM(value)}</Text>,
          },
          {
            Header: 'Summary',
            accessor: 'summary',
          },
          {
            Header: 'Root cause',
            accessor: 'rootCause',
          },
          {
            Header: 'Remediation',
            accessor: 'remediation',
          },
          {
            Header: 'Impact',
            accessor: 'impact',
          },
          {
            Header: 'Newsletter sent',
            accessor: 'newsletterSent',
            Cell: ({ value }) => <Text>{value ? 'Yes' : 'No'}</Text>,
          },
          {
            Header: 'Jira issue',
            accessor: 'jiraIssueURL',
            Cell: ({ value }) => (
              <Link href={value} isExternal color="#3295cf">
                {value}
              </Link>
            ),
          },
          isAdmin && {
            Header: () => <ConfigIcon boxSize={9} />,
            accessor: 'configuration',
            Cell: (e: any) => {
              const incidentId = e.row.values.id;
              return (
                <Flex width="full" justifyContent="center">
                  <PencilIcon
                    boxSize={8}
                    onClick={() => navigateToIncidentConfig(incidentId)}
                    cursor="pointer"
                  />
                  <TrashIcon
                    boxSize={8}
                    cursor="pointer"
                    onClick={() => {
                      onDeleteIncident(incidentId);
                    }}
                  />
                </Flex>
              );
            },
          },
          {
            Header: 'Id',
            accessor: 'id',
          },
        ] as Column[]
      ).filter(Boolean),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [navigateToIncidentConfig, isAdmin],
  );

  const data = useMemo(
    () =>
      Object.entries(incidents).map(([incidentId, incident]) => {
        const { startTime, endTime, totalDowntime } = incident;
        const totalDowntimeLocal =
          totalDowntime != null
            ? totalDowntime
            : new Date(endTime).getTime() - new Date(startTime).getTime();

        return {
          ...incident,
          totalDowntime: totalDowntimeLocal,
          hrTotalDowntime: convertMsToHM(totalDowntimeLocal),
          incidentId,
        };
      }),
    [incidents],
  );

  const initialState = {
    hiddenColumns: ['id'],
    sortBy: [
      {
        id: 'startTime',
        desc: false,
      },
    ],
  };
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        initialState,
      },
      useSortBy,
    );

  const renderTableHead = () => {
    return (
      <Thead
        position="sticky"
        top={0}
        boxShadow="xl"
        p="6"
        rounded="md"
        bg="#e6e7e8"
      >
        {headerGroups.map((headerGroup) => {
          const { headers } = headerGroup;

          return (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headers.map((column: any) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  paddingY={0}
                  paddingX={3}
                  boxSizing="border-box"
                >
                  <Text
                    fontSize="s"
                    fontWeight="medium"
                    textTransform="capitalize"
                    color="#414042"
                    whiteSpace="nowrap"
                    align="left"
                  >
                    {column.render('Header')}

                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <>&nbsp;^</>
                      ) : (
                        <>&nbsp;⌄</>
                      )
                    ) : null}
                  </Text>
                </Th>
              ))}
            </Tr>
          );
        })}
      </Thead>
    );
  };

  const renderTableBody = () => {
    if (rows.length === 0) {
      return (
        <Tr>
          <Td colSpan={columns.length} paddingY={5}>
            <HStack justify="center">
              <SuccessIcon fill="green" />
              <Text>There are no incidents in the chosen time period</Text>
            </HStack>
          </Td>
        </Tr>
      );
    }

    const renderBodyRows = () => {
      return rows.map((row) => {
        prepareRow(row);

        return (
          <Tr borderBottom="1px solid #e6e7e8" {...row.getRowProps()}>
            {row.cells.map((cell) => {
              const cellProps = cell.getCellProps();
              return (
                <Td
                  {...cellProps}
                  fontSize="xs"
                  wordBreak="break-word"
                  verticalAlign="top"
                  align="left"
                  paddingX={3}
                >
                  {cell.render('Cell')}
                </Td>
              );
            })}
          </Tr>
        );
      });
    };

    return <Tbody {...getTableBodyProps()}>{renderBodyRows()}</Tbody>;
  };

  return (
    <Table
      border="1px solid #e6e7e8"
      position="relative"
      {...tableProps}
      {...getTableProps()}
    >
      {renderTableHead()}
      {renderTableBody()}
    </Table>
  );
};
