import { useMemo } from 'react';
import { decode } from '../../utils/jwtHelpers';
import { JWT } from './types';

interface UseUserScopeProps {
  token: string;
}

export const useUserScope = ({ token }: UseUserScopeProps) =>
  useMemo(() => {
    if (!token) return [];
    return decode<JWT>(token).scope.toUpperCase().split(' ');
  }, [token]);
