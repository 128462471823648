import { Stack, Flex } from '@chakra-ui/react';
import { FC, ReactNode } from 'react';
import { Footer } from './Footer';
import { Header } from './Header';
import { LeftNav } from './LeftNav';

export type AppPageProps = {
  children: ReactNode;
};

export const AppPage: FC<AppPageProps> = (props) => {
  const { children } = props;
  return (
    <Stack minHeight="600px" height="100vh" width="100vw" spacing={0}>
      <Header />

      <Flex flex={1} height={0}>
        <LeftNav />

        <Flex flex={1} width={0}>
          {children}
        </Flex>
      </Flex>

      <Footer />
    </Stack>
  );
};
