import { createIcon } from '@chakra-ui/react';

export const RefreshRateIcon = createIcon({
  viewBox: '0 0 68 68',
  defaultProps: {
    width: '20',
    height: 'auto',
  },
  path: [
    <path
      style={{
        fill: '#414042',
      }}
      d="M13,35c-0.552,0-1-0.448-1-1c0-12.131,9.869-22,22-22c5.876,0,11.4,2.289,15.557,6.444    c0.391,0.391,0.391,1.023,0,1.414s-1.023,0.391-1.414,0C44.364,16.081,39.342,14,34,14c-11.028,0-20,8.972-20,20    C14,34.552,13.552,35,13,35z"
    />,
    <path
      style={{
        fill: '#414042',
      }}
      d="M34,56c-5.916,0-11.467-2.315-15.632-6.52c-0.389-0.393-0.386-1.026,0.007-1.415    c0.392-0.387,1.027-0.385,1.414,0.007C23.575,51.895,28.622,54,34,54c11.028,0,20-8.972,20-20c0-0.552,0.447-1,1-1s1,0.448,1,1    C56,46.131,46.131,56,34,56z"
    />,
    <path
      style={{
        fill: '#DC0032',
      }}
      d="M13,40.414l-8.707-8.707c-0.391-0.391-0.391-1.023,0-1.414s1.023-0.391,1.414,0L13,37.586    l7.293-7.293c0.391-0.391,1.023-0.391,1.414,0s0.391,1.023,0,1.414L13,40.414z"
    />,
    <path
      style={{
        fill: '#DC0032',
      }}
      d="M63,38c-0.256,0-0.512-0.098-0.707-0.293L55,30.414l-7.293,7.293c-0.391,0.391-1.023,0.391-1.414,0    s-0.391-1.023,0-1.414L55,27.586l8.707,8.707c0.391,0.391,0.391,1.023,0,1.414C63.512,37.902,63.256,38,63,38z"
    />,
  ],
});
