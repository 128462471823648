import invariant from 'tiny-invariant';
import Keycloak from 'keycloak-js';
import { configuration } from './configuration';

export const getKeycloak = () => {
  invariant(
    configuration.environment === 'prod',
    'Keyloack is not supported on preprod',
  );

  const { url, realm, clientId } = configuration?.sso;

  return new Keycloak({
    url,
    realm,
    clientId,
  });
};
