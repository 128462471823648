import { FC } from 'react';
import { Center, Flex, Spinner } from '@chakra-ui/react';
import { useAuth } from '../providers/AuthProvider';

export const AwaitAuth: FC<React.PropsWithChildren> = ({ children }) => {
  const { state } = useAuth();

  if (state === 'loading' || state === 'signedOut') {
    return (
      <Flex flex={1} width="100vw" height="100vh">
        <Center height="full" width="full">
          <Spinner size="xl" color="dpdRed.500" />
        </Center>
      </Flex>
    );
  }

  return <>{children}</>;
};
