import { useCallback } from 'react';
import { AxiosError } from 'axios';
import {
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';
import { useApi } from '../../../api/ApiProvider';
import { ApiResponse } from '../../../api/types';
import { AppGroup, StatisticDetail } from '../../applicationMonitorPage/types';

export type TicketStatisticsIncidentsGraphData = {
  applicationGroups: {
    [key in string]: AppGroup;
  };
  tickets: StatisticDetail[];
};

type QueryResult = ApiResponse<TicketStatisticsIncidentsGraphData>;

type UseTicketStatisticsTableAndIncidentsQueryParams = {
  monthValue: string | null;
};

export const createTicketStatisticsTableAndIncidentsCacheKey = (
  params: UseTicketStatisticsTableAndIncidentsQueryParams,
) => ['ticketStatisticsBySeverity', params];

export const useTicketStatisticsTableAndIncidentsQuery = (
  params: UseTicketStatisticsTableAndIncidentsQueryParams,
  opts?: UseQueryOptions<QueryResult, AxiosError>,
) => {
  const api = useApi();

  const { monthValue } = params;

  const queryFn = async ({ signal }: { signal?: AbortSignal }) => {
    const { data } = await api.get<QueryResult>(
      `/ticket/incident?date=${monthValue ?? ''}`,
      {
        signal,
      },
    );
    return data;
  };

  const query = useQuery<QueryResult, AxiosError>(
    createTicketStatisticsTableAndIncidentsCacheKey(params),
    queryFn,
    opts,
  );

  return query;
};

export const useInvalidateTicketStatisticsIncidentsGraphQuery = (
  params: UseTicketStatisticsTableAndIncidentsQueryParams,
) => {
  const queryClient = useQueryClient();

  const invalidate = useCallback(
    () =>
      queryClient.invalidateQueries(
        createTicketStatisticsTableAndIncidentsCacheKey(params),
      ),
    [params, queryClient],
  );

  return invalidate;
};
