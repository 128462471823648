import { Box, Flex, Stack, Text } from '@chakra-ui/react';
import { format } from 'date-fns';
import { FC } from 'react';
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { millisecondsToHours } from '../../../utils/timeHelpers';
import { roundToPlaces } from '../../../utils/mathHelpers';

import { ResolutionTimeAndChartData } from './useResolutionTimeTableAndHoursChartQuery';

type TicketStatisticResolutionTimeChartProps = {
  chartData: ResolutionTimeAndChartData;
  monthValue: string;
  applications: {
    id: string;
    name: string;
    applicationGroupId: string;
  }[];
};

const redColor = '#dc0032';
const yellowColor = '#ffc004';

export const TicketStatisticResolutionTimeChart: FC<
  TicketStatisticResolutionTimeChartProps
> = (props) => {
  const { chartData, monthValue, applications } = props;
  const { entries } = chartData;
  const applicationsWithIncidents = applications.filter(({ id }) => {
    const { blockerAverageResolutionTime, criticalAverageResolutionTime } =
      entries.find(({ applicationId }) => applicationId === id) ?? {
        blockerAverageResolutionTime: 0,
        criticalAverageResolutionTime: 0,
      };

    return (
      blockerAverageResolutionTime > 0 || criticalAverageResolutionTime > 0
    );
  });

  const sortedApplications = applicationsWithIncidents.sort(
    ({ name: a }, { name: b }) => {
      if (a.toLowerCase() === b.toLowerCase()) return 0;
      return a.toLowerCase() > b.toLowerCase() ? 1 : -1;
    },
  );

  const chartEntries = sortedApplications.map((appData) => {
    const { id, name } = appData;
    const appEntry = entries.find(
      ({ applicationId }) => applicationId === id,
    ) ?? {
      blockerAverageResolutionTime: 0,
      criticalAverageResolutionTime: 0,
    };

    return {
      name,
      id,
      blocker: roundToPlaces(
        millisecondsToHours(appEntry.blockerAverageResolutionTime),
        1,
      ),
      critical: roundToPlaces(
        millisecondsToHours(appEntry.criticalAverageResolutionTime),
        1,
      ),
    };
  });

  const renderColorfulLegendText = (value: string) => {
    return (
      <Box as="span" fontSize="10px" color="#414042" padding={3}>
        {value}
      </Box>
    );
  };

  const renderChartHeader = () => {
    return (
      <Flex
        backgroundColor="#e6e7e8"
        textAlign="end"
        width="full"
        paddingLeft={4}
        paddingRight={4}
        paddingTop={3}
        paddingBottom={3}
        justifyContent="space-between"
      >
        <Text color="#414042">
          Average resolution time - blocker and critical (hours)
        </Text>
        <Text color="#808285">{format(new Date(monthValue), 'MMMM yyyy')}</Text>
      </Flex>
    );
  };

  const chartIsShown = chartEntries.length > 0;

  const renderChart = () => {
    return (
      <ResponsiveContainer width="100%" height="100%">
        <ComposedChart data={chartEntries}>
          <CartesianGrid stroke="#f5f5f5" />
          <XAxis
            dataKey="name"
            fontSize={12}
            domain={['No app in this scope', 'No app in this scope']}
            interval={0}
            tickMargin={10}
            textAnchor="end"
            angle={300}
            height={140}
          />
          <YAxis
            domain={[0, (dataMax: number) => Math.max(Math.ceil(dataMax), 16)]}
            type="number"
            fontSize={12}
            interval={0}
            scale="linear"
          />
          <Tooltip />
          <Legend
            layout="horizontal"
            align="center"
            verticalAlign="top"
            iconSize={20}
            wrapperStyle={{ paddingBottom: '5px' }}
            formatter={renderColorfulLegendText}
          />

          <Bar
            dataKey="blocker"
            fill={redColor}
            legendType="circle"
            name="Blocker"
            maxBarSize={50}
          />
          <Bar
            dataKey="critical"
            fill={yellowColor}
            legendType="circle"
            name="Critical"
            maxBarSize={50}
          />
        </ComposedChart>
      </ResponsiveContainer>
    );
  };

  return (
    <Stack height="full">
      <Stack marginTop={10} border="1px solid #e6e7e8" padding={0} flex={1}>
        {renderChartHeader()}
        <Stack
          flex={1}
          direction={['column', null, null, null, 'row']}
          minHeight={['800px', null, null, null, '500px']}
          alignItems="stretch"
        >
          {chartIsShown && renderChart()}
        </Stack>
      </Stack>
    </Stack>
  );
};
