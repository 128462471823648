import { FC, useMemo } from 'react';
import { Center, Grid, GridItem, Spinner } from '@chakra-ui/react';
import { OngoingIncidentsIcon } from '../../icons/OngoingIncidentsIcon';
import { RefreshRateIcon } from '../../icons/RefreshRateIcon';
import { ServiceCheckedIcon } from '../../icons/ServiceCheckedIcon';
import { WorkingNormallyIcon } from '../../icons/WorkingNormallyIcon';
import { DashboardStatus, StatusCode } from '../applicationMonitorPage/types';
import { ApplicationMonitorTable } from './ApplicationMonitorTable';
import { AppPage } from './AppPage';
import { useDashboardDataQuery } from './useDashboardDataQuery';
import { Tile } from './appsOverview/Tile';

export const AppsOverview: FC = () => {
  const refreshRate = 1;

  const { data: dashboardData, isLoading } = useDashboardDataQuery({
    refetchInterval: refreshRate * 60000,
  });

  const applications = useMemo(() => {
    if (!dashboardData) return [];

    const { entries } = dashboardData;

    const countStatuses = (statuses: DashboardStatus[], status: StatusCode) =>
      statuses.filter((s) => s.status === status).length;

    const isStatusUp = (status: StatusCode) =>
      ['OK', 'OK_WITH_INFORMATION'].includes(status);

    const sortedEntries = entries.sort((prevApp, nextApp) => {
      // NOTE: 1st level: Current status: Down / Up, down first
      if (isStatusUp(prevApp.liveStatus) !== isStatusUp(nextApp.liveStatus)) {
        return !isStatusUp(prevApp.liveStatus) && isStatusUp(nextApp.liveStatus)
          ? -1
          : 1;
      }

      // NOTE: 2nd level by past statuses
      const prevAppPastStatuses = Object.entries(prevApp.pastStatuses).map(
        ([, status]) => status as DashboardStatus,
      );
      const nextAppPastStatuses = Object.entries(nextApp.pastStatuses).map(
        ([, status]) => status as DashboardStatus,
      );

      const outages =
        countStatuses(nextAppPastStatuses, 'OUTAGE') -
        countStatuses(prevAppPastStatuses, 'OUTAGE');

      if (!!outages) {
        return outages;
      }

      const disruption =
        countStatuses(nextAppPastStatuses, 'DISRUPTION') -
        countStatuses(prevAppPastStatuses, 'DISRUPTION');

      if (!!disruption) {
        return disruption;
      }

      // NOTE: 3rd level by recent 24h downtime
      return nextApp.recentDowntimeMillis - prevApp.recentDowntimeMillis;
    });

    return sortedEntries;
  }, [dashboardData]);

  const serviceChecked = applications.length;

  const workingNormally = applications.filter((app) =>
    ['OK', 'OK_WITH_INFORMATION'].includes(app.liveStatus),
  ).length;

  const ongoingIncidents = serviceChecked - workingNormally;

  const dashboardHalfLength = Math.ceil(applications.length / 2);
  const firstTableData = applications.slice(0, dashboardHalfLength);
  const secondTableData = applications.slice(dashboardHalfLength);

  if (isLoading) {
    return (
      <AppPage>
        <Center height="full" width="full">
          <Spinner />
        </Center>
      </AppPage>
    );
  }

  return (
    <AppPage>
      <Grid
        paddingTop="8"
        templateColumns="repeat(4,1fr)"
        columnGap="8"
        rowGap="6"
        width="95vw"
        margin="auto"
      >
        <GridItem
          colSpan={1}
          backgroundColor="white"
          borderColor="dpdGray.200"
          borderWidth={2}
        >
          <Tile
            icon={<ServiceCheckedIcon boxSize="50px" />}
            value={String(serviceChecked)}
            label="Services checked"
          />
        </GridItem>
        <GridItem
          colSpan={1}
          backgroundColor="white"
          borderColor="dpdGray.200"
          borderWidth={2}
        >
          <Tile
            icon={<WorkingNormallyIcon boxSize="50px" />}
            value={String(workingNormally)}
            label="Working normally"
          />
        </GridItem>
        <GridItem
          colSpan={1}
          backgroundColor="white"
          borderColor="dpdGray.200"
          borderWidth={2}
        >
          <Tile
            icon={<OngoingIncidentsIcon boxSize="50px" />}
            value={String(ongoingIncidents)}
            label="Ongoing incidents"
          />
        </GridItem>
        <GridItem
          colSpan={1}
          backgroundColor="white"
          borderColor="dpdGray.200"
          borderWidth={2}
        >
          <Tile
            icon={<RefreshRateIcon boxSize="50px" />}
            value={`${String(refreshRate)}m`}
            label="Refresh rate"
          />
        </GridItem>
        <GridItem colSpan={2}>
          <ApplicationMonitorTable data={firstTableData} />
        </GridItem>
        <GridItem colSpan={2}>
          <ApplicationMonitorTable data={secondTableData} />
        </GridItem>
      </Grid>
    </AppPage>
  );
};
