import { context } from 'msw';
import { randomNumber } from '../../utils/mockHelpers';
import { appNames } from '../data';

export const response = () => {
  const response = {
    entries: appNames.map((_, i) => ({
      applicationId: String(i),
      blockerAverageResolutionTime: randomNumber(
        5 * 1000 * 60 * 60,
        0.5 * 1000 * 60 * 60,
      ),
      criticalAverageResolutionTime: randomNumber(
        5 * 1000 * 60 * 60,
        0.5 * 1000 * 60 * 60,
      ),
    })),
  };

  return context.json(response);
};
