import {context} from 'msw';
import {randomNumber} from '../../utils/mockHelpers';

export const response = () => {
  const periodCount = 6;
    const incidentName = [
        'BLOCKER',
        'BLOCKER',
        'CRITICAL',
        'CRITICAL',
    ];
  const response = {
    severities: incidentName.map((severity, index) => {
      return {
        id: String(index),
        severity,
        thirdPartyCause: index % 2 === 1,

      };
    }),

    periods: Array.from({ length: periodCount }).map((_, index) => {
      const currentYear = new Date().getFullYear();
      const currentMonth = (index + 1) % 12;

      return {
        id: String(index),
        dateTime: `${currentYear}-${currentMonth.toString().padStart(2, '0')}`,
      };
    }),

    entries: (() => {
      let result = [];

      for (let periodId = 0; periodId < periodCount; periodId++) {
        for (let id = 0; id < incidentName.length; id++) {
          if (Math.round(Math.random())) {
            result.push({
              periodId: String(periodId),
              severityId: String(id),
              numberOfTickets: randomNumber(50),
            });
          }
        }
      }
      return result;
    })(),
  };

  return context.json(response);
};
