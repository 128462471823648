import { MockedResponse, RestRequest } from 'msw';
import { decode } from '../utils/jwtHelpers';
import { getTimeInSeconds } from '../utils/timeHelpers';

interface Token {
  exp: number;
}

export const isAutorized = (req: RestRequest) => {
  const header = req.headers.get('Authorization');
  if (!header) return false;

  const [type, token] = header.split(' ');

  if (!token) return false;

  if (type === 'Basic') {
    const [username, password] = atob(token).split(':');
    return username && password;
  }

  const { exp } = decode<Token>(token);
  return exp > getTimeInSeconds();
};

export const authorize = (request: RestRequest) => {
  return (res: MockedResponse) => {
    if (!isAutorized(request)) {
      res.status = 401;
    }

    return res;
  };
};
