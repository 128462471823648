import { context } from 'msw';
import { DashboardData } from '../../pages/dashboardPage/useDashboardDataQuery';

export const response = () => context.json(responseBody);

const responseBody: DashboardData = {
  entries: [
    {
      liveStatus: 'OK',
      applicationName: 'BuDah',
      recentUptime: 100,
      recentDowntimeMillis: 0,
      pastStatuses: {
        '2024-03-22': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-21': {
          status: 'OUTAGE',
          newsletterSent: true,
        },
        '2024-03-20': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-19': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-18': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-17': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-16': {
          status: 'OK',
          newsletterSent: false,
        },
      },
    },
    {
      liveStatus: 'OK',
      applicationName: 'CMS',
      recentUptime: 100,
      recentDowntimeMillis: 0,
      pastStatuses: {
        '2024-03-22': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-21': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-20': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-19': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-18': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-17': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-16': {
          status: 'OK',
          newsletterSent: false,
        },
      },
    },
    {
      liveStatus: 'OK',
      applicationName: 'CMS (Back Office)',
      recentUptime: 100,
      recentDowntimeMillis: 0,
      pastStatuses: {
        '2024-03-22': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-21': {
          status: 'OUTAGE',
          newsletterSent: true,
        },
        '2024-03-20': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-19': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-18': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-17': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-16': {
          status: 'OK',
          newsletterSent: false,
        },
      },
    },
    {
      liveStatus: 'OK',
      applicationName: 'COL2022',
      recentUptime: 100,
      recentDowntimeMillis: 0,
      pastStatuses: {
        '2024-03-22': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-21': {
          status: 'OUTAGE',
          newsletterSent: true,
        },
        '2024-03-20': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-19': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-18': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-17': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-16': {
          status: 'OK',
          newsletterSent: false,
        },
      },
    },
    {
      liveStatus: 'OK',
      applicationName: 'CSN/Intranet',
      recentUptime: 100,
      recentDowntimeMillis: 0,
      pastStatuses: {
        '2024-03-22': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-21': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-20': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-19': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-18': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-17': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-16': {
          status: 'OK',
          newsletterSent: false,
        },
      },
    },
    {
      liveStatus: 'OK',
      applicationName: 'Chatbot',
      recentUptime: 100,
      recentDowntimeMillis: 0,
      pastStatuses: {
        '2024-03-22': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-21': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-20': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-19': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-18': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-17': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-16': {
          status: 'OK',
          newsletterSent: false,
        },
      },
    },
    {
      liveStatus: 'OK',
      applicationName: 'Coldbuster/MDU Food',
      recentUptime: 100,
      recentDowntimeMillis: 0,
      pastStatuses: {
        '2024-03-22': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-21': {
          status: 'OUTAGE',
          newsletterSent: true,
        },
        '2024-03-20': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-19': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-18': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-17': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-16': {
          status: 'OK',
          newsletterSent: false,
        },
      },
    },
    {
      liveStatus: 'OK',
      applicationName: 'Consumer Activation',
      recentUptime: 100,
      recentDowntimeMillis: 0,
      pastStatuses: {
        '2024-03-22': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-21': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-20': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-19': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-18': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-17': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-16': {
          status: 'OK',
          newsletterSent: false,
        },
      },
    },
    {
      liveStatus: 'OK',
      applicationName: 'Control Tower',
      recentUptime: 100,
      recentDowntimeMillis: 0,
      pastStatuses: {
        '2024-03-22': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-21': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-20': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-19': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-18': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-17': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-16': {
          status: 'OK',
          newsletterSent: false,
        },
      },
    },
    {
      liveStatus: 'OK',
      applicationName: 'DPD Admin',
      recentUptime: 100,
      recentDowntimeMillis: 0,
      pastStatuses: {
        '2024-03-22': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-21': {
          status: 'OUTAGE',
          newsletterSent: true,
        },
        '2024-03-20': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-19': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-18': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-17': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-16': {
          status: 'OK',
          newsletterSent: false,
        },
      },
    },
    {
      liveStatus: 'OK',
      applicationName: 'DPD Billing',
      recentUptime: 100,
      recentDowntimeMillis: 0,
      pastStatuses: {
        '2024-03-22': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-21': {
          status: 'OUTAGE',
          newsletterSent: true,
        },
        '2024-03-20': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-19': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-18': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-17': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-16': {
          status: 'OK',
          newsletterSent: false,
        },
      },
    },
    {
      liveStatus: 'OK',
      applicationName: 'DPD Connect',
      recentUptime: 100,
      recentDowntimeMillis: 0,
      pastStatuses: {
        '2024-03-22': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-21': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-20': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-19': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-18': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-17': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-16': {
          status: 'OK',
          newsletterSent: false,
        },
      },
    },
    {
      liveStatus: 'OK',
      applicationName: 'DPD Register AR',
      recentUptime: 100,
      recentDowntimeMillis: 0,
      pastStatuses: {
        '2024-03-22': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-21': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-20': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-19': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-18': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-17': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-16': {
          status: 'OK',
          newsletterSent: false,
        },
      },
    },
    {
      liveStatus: 'OUTAGE',
      applicationName: 'DPD Register BE',
      recentUptime: 88.58,
      recentDowntimeMillis: 9863168,
      pastStatuses: {
        '2024-03-22': {
          status: 'OUTAGE',
          newsletterSent: false,
        },
        '2024-03-21': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-20': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-19': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-18': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-17': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-16': {
          status: 'OK',
          newsletterSent: false,
        },
      },
    },
    {
      liveStatus: 'OUTAGE',
      applicationName: 'DPD Register CH',
      recentUptime: 88.13,
      recentDowntimeMillis: 10256385,
      pastStatuses: {
        '2024-03-22': {
          status: 'OUTAGE',
          newsletterSent: false,
        },
        '2024-03-21': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-20': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-19': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-18': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-17': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-16': {
          status: 'OK',
          newsletterSent: false,
        },
      },
    },
    {
      liveStatus: 'OUTAGE',
      applicationName: 'DPD Register EE',
      recentUptime: 88.13,
      recentDowntimeMillis: 10256384,
      pastStatuses: {
        '2024-03-22': {
          status: 'OUTAGE',
          newsletterSent: false,
        },
        '2024-03-21': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-20': {
          status: 'OK_WITH_INFORMATION',
          newsletterSent: false,
        },
        '2024-03-19': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-18': {
          status: 'OUTAGE',
          newsletterSent: false,
        },
        '2024-03-17': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-16': {
          status: 'OK',
          newsletterSent: false,
        },
      },
    },
    {
      liveStatus: 'OUTAGE',
      applicationName: 'DPD Register HR',
      recentUptime: 88.13,
      recentDowntimeMillis: 10256384,
      pastStatuses: {
        '2024-03-22': {
          status: 'OUTAGE',
          newsletterSent: false,
        },
        '2024-03-21': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-20': {
          status: 'OK_WITH_INFORMATION',
          newsletterSent: false,
        },
        '2024-03-19': {
          status: 'OK_WITH_INFORMATION',
          newsletterSent: false,
        },
        '2024-03-18': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-17': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-16': {
          status: 'OK',
          newsletterSent: false,
        },
      },
    },
    {
      liveStatus: 'OUTAGE',
      applicationName: 'DPD Register HU',
      recentUptime: 88.13,
      recentDowntimeMillis: 10256384,
      pastStatuses: {
        '2024-03-22': {
          status: 'OUTAGE',
          newsletterSent: false,
        },
        '2024-03-21': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-20': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-19': {
          status: 'OK_WITH_INFORMATION',
          newsletterSent: false,
        },
        '2024-03-18': {
          status: 'OK_WITH_INFORMATION',
          newsletterSent: false,
        },
        '2024-03-17': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-16': {
          status: 'OK',
          newsletterSent: false,
        },
      },
    },
    {
      liveStatus: 'OUTAGE',
      applicationName: 'DPD Register LT',
      recentUptime: 88.51,
      recentDowntimeMillis: 9925493,
      pastStatuses: {
        '2024-03-22': {
          status: 'OUTAGE',
          newsletterSent: false,
        },
        '2024-03-21': {
          status: 'OK_WITH_INFORMATION',
          newsletterSent: false,
        },
        '2024-03-20': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-19': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-18': {
          status: 'OUTAGE',
          newsletterSent: false,
        },
        '2024-03-17': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-16': {
          status: 'OK',
          newsletterSent: false,
        },
      },
    },
    {
      liveStatus: 'OUTAGE',
      applicationName: 'DPD Register LU',
      recentUptime: 88.58,
      recentDowntimeMillis: 9863167,
      pastStatuses: {
        '2024-03-22': {
          status: 'OUTAGE',
          newsletterSent: false,
        },
        '2024-03-21': {
          status: 'OK_WITH_INFORMATION',
          newsletterSent: false,
        },
        '2024-03-20': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-19': {
          status: 'OK_WITH_INFORMATION',
          newsletterSent: false,
        },
        '2024-03-18': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-17': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-16': {
          status: 'OK',
          newsletterSent: false,
        },
      },
    },
    {
      liveStatus: 'OUTAGE',
      applicationName: 'DPD Register LV',
      recentUptime: 88.58,
      recentDowntimeMillis: 9863167,
      pastStatuses: {
        '2024-03-22': {
          status: 'OUTAGE',
          newsletterSent: false,
        },
        '2024-03-21': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-20': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-19': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-18': {
          status: 'OUTAGE',
          newsletterSent: false,
        },
        '2024-03-17': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-16': {
          status: 'OK',
          newsletterSent: false,
        },
      },
    },
    {
      liveStatus: 'OUTAGE',
      applicationName: 'DPD Register NL',
      recentUptime: 88.13,
      recentDowntimeMillis: 10256384,
      pastStatuses: {
        '2024-03-22': {
          status: 'OUTAGE',
          newsletterSent: false,
        },
        '2024-03-21': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-20': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-19': {
          status: 'OK_WITH_INFORMATION',
          newsletterSent: false,
        },
        '2024-03-18': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-17': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-16': {
          status: 'OK',
          newsletterSent: false,
        },
      },
    },
    {
      liveStatus: 'OUTAGE',
      applicationName: 'DPD Register SI',
      recentUptime: 88.13,
      recentDowntimeMillis: 10256383,
      pastStatuses: {
        '2024-03-22': {
          status: 'OUTAGE',
          newsletterSent: false,
        },
        '2024-03-21': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-20': {
          status: 'OK_WITH_INFORMATION',
          newsletterSent: false,
        },
        '2024-03-19': {
          status: 'OK_WITH_INFORMATION',
          newsletterSent: false,
        },
        '2024-03-18': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-17': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-16': {
          status: 'OK',
          newsletterSent: false,
        },
      },
    },
    {
      liveStatus: 'OUTAGE',
      applicationName: 'DPD Register SK',
      recentUptime: 88.06,
      recentDowntimeMillis: 10313305,
      pastStatuses: {
        '2024-03-22': {
          status: 'OUTAGE',
          newsletterSent: false,
        },
        '2024-03-21': {
          status: 'OK_WITH_INFORMATION',
          newsletterSent: false,
        },
        '2024-03-20': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-19': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-18': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-17': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-16': {
          status: 'OK',
          newsletterSent: false,
        },
      },
    },
    {
      liveStatus: 'OK',
      applicationName: 'DPD Shipping',
      recentUptime: 100,
      recentDowntimeMillis: 0,
      pastStatuses: {
        '2024-03-22': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-21': {
          status: 'OUTAGE',
          newsletterSent: true,
        },
        '2024-03-20': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-19': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-18': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-17': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-16': {
          status: 'OK',
          newsletterSent: false,
        },
      },
    },
    {
      liveStatus: 'OK',
      applicationName: 'DataHub',
      recentUptime: 100,
      recentDowntimeMillis: 0,
      pastStatuses: {
        '2024-03-22': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-21': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-20': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-19': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-18': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-17': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-16': {
          status: 'OK',
          newsletterSent: false,
        },
      },
    },
    {
      liveStatus: 'OK',
      applicationName: 'Duties (Back Office)',
      recentUptime: 100,
      recentDowntimeMillis: 0,
      pastStatuses: {
        '2024-03-22': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-21': {
          status: 'OUTAGE',
          newsletterSent: true,
        },
        '2024-03-20': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-19': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-18': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-17': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-16': {
          status: 'OK',
          newsletterSent: false,
        },
      },
    },
    {
      liveStatus: 'OK',
      applicationName: 'Duties and Taxes',
      recentUptime: 100,
      recentDowntimeMillis: 0,
      pastStatuses: {
        '2024-03-22': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-21': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-20': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-19': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-18': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-17': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-16': {
          status: 'OK',
          newsletterSent: false,
        },
      },
    },
    {
      liveStatus: 'OK',
      applicationName: 'Export Control',
      recentUptime: 100,
      recentDowntimeMillis: 0,
      pastStatuses: {
        '2024-03-22': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-21': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-20': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-19': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-18': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-17': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-16': {
          status: 'OK',
          newsletterSent: false,
        },
      },
    },
    {
      liveStatus: 'OK',
      applicationName: 'Export Gateway',
      recentUptime: 100,
      recentDowntimeMillis: 0,
      pastStatuses: {
        '2024-03-22': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-21': {
          status: 'OUTAGE',
          newsletterSent: true,
        },
        '2024-03-20': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-19': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-18': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-17': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-16': {
          status: 'OK',
          newsletterSent: false,
        },
      },
    },
    {
      liveStatus: 'OK',
      applicationName: 'FMPv1',
      recentUptime: 100,
      recentDowntimeMillis: 0,
      pastStatuses: {
        '2024-03-22': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-21': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-20': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-19': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-18': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-17': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-16': {
          status: 'OK',
          newsletterSent: false,
        },
      },
    },
    {
      liveStatus: 'OK',
      applicationName: 'FMPv2',
      recentUptime: 100,
      recentDowntimeMillis: 0,
      pastStatuses: {
        '2024-03-22': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-21': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-20': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-19': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-18': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-17': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-16': {
          status: 'OK',
          newsletterSent: false,
        },
      },
    },
    {
      liveStatus: 'OK',
      applicationName: 'FastTrack',
      recentUptime: 100,
      recentDowntimeMillis: 0,
      pastStatuses: {
        '2024-03-22': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-21': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-20': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-19': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-18': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-17': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-16': {
          status: 'OK',
          newsletterSent: false,
        },
      },
    },
    {
      liveStatus: 'OK',
      applicationName: 'GDMT',
      recentUptime: 100,
      recentDowntimeMillis: 0,
      pastStatuses: {
        '2024-03-22': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-21': {
          status: 'OK_WITH_INFORMATION',
          newsletterSent: false,
        },
        '2024-03-20': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-19': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-18': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-17': {
          status: 'DISRUPTION',
          newsletterSent: false,
        },
        '2024-03-16': {
          status: 'OK',
          newsletterSent: false,
        },
      },
    },
    {
      liveStatus: 'OK',
      applicationName: 'GRMT',
      recentUptime: 100,
      recentDowntimeMillis: 0,
      pastStatuses: {
        '2024-03-22': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-21': {
          status: 'OUTAGE',
          newsletterSent: true,
        },
        '2024-03-20': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-19': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-18': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-17': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-16': {
          status: 'OK',
          newsletterSent: false,
        },
      },
    },
    {
      liveStatus: 'OK',
      applicationName: 'GSMT',
      recentUptime: 100,
      recentDowntimeMillis: 0,
      pastStatuses: {
        '2024-03-22': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-21': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-20': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-19': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-18': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-17': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-16': {
          status: 'OK',
          newsletterSent: false,
        },
      },
    },
    {
      liveStatus: 'OK',
      applicationName: 'GeoCheck',
      recentUptime: 100,
      recentDowntimeMillis: 0,
      pastStatuses: {
        '2024-03-22': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-21': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-20': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-19': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-18': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-17': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-16': {
          status: 'OK',
          newsletterSent: false,
        },
      },
    },
    {
      liveStatus: 'OK',
      applicationName: 'GeoTrack',
      recentUptime: 100,
      recentDowntimeMillis: 0,
      pastStatuses: {
        '2024-03-22': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-21': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-20': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-19': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-18': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-17': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-16': {
          status: 'OK',
          newsletterSent: false,
        },
      },
    },
    {
      liveStatus: 'OK',
      applicationName: 'IDM2',
      recentUptime: 100,
      recentDowntimeMillis: 0,
      pastStatuses: {
        '2024-03-22': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-21': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-20': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-19': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-18': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-17': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-16': {
          status: 'OK',
          newsletterSent: false,
        },
      },
    },
    {
      liveStatus: 'OK',
      applicationName: 'IT4EM',
      recentUptime: 100,
      recentDowntimeMillis: 0,
      pastStatuses: {
        '2024-03-22': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-21': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-20': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-19': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-18': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-17': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-16': {
          status: 'OK',
          newsletterSent: false,
        },
      },
    },
    {
      liveStatus: 'OK',
      applicationName: 'IT4EM CH',
      recentUptime: 100,
      recentDowntimeMillis: 0,
      pastStatuses: {
        '2024-03-22': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-21': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-20': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-19': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-18': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-17': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-16': {
          status: 'OK',
          newsletterSent: false,
        },
      },
    },
    {
      liveStatus: 'OK',
      applicationName: 'IT4EM CZ',
      recentUptime: 100,
      recentDowntimeMillis: 0,
      pastStatuses: {
        '2024-03-22': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-21': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-20': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-19': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-18': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-17': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-16': {
          status: 'OK',
          newsletterSent: false,
        },
      },
    },
    {
      liveStatus: 'OK',
      applicationName: 'IT4EM EE',
      recentUptime: 100,
      recentDowntimeMillis: 0,
      pastStatuses: {
        '2024-03-22': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-21': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-20': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-19': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-18': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-17': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-16': {
          status: 'OK',
          newsletterSent: false,
        },
      },
    },
    {
      liveStatus: 'OK',
      applicationName: 'IT4EM HR',
      recentUptime: 100,
      recentDowntimeMillis: 0,
      pastStatuses: {
        '2024-03-22': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-21': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-20': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-19': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-18': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-17': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-16': {
          status: 'OK',
          newsletterSent: false,
        },
      },
    },
    {
      liveStatus: 'OK',
      applicationName: 'IT4EM HU',
      recentUptime: 100,
      recentDowntimeMillis: 0,
      pastStatuses: {
        '2024-03-22': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-21': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-20': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-19': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-18': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-17': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-16': {
          status: 'OK',
          newsletterSent: false,
        },
      },
    },
    {
      liveStatus: 'OK',
      applicationName: 'IT4EM LT',
      recentUptime: 100,
      recentDowntimeMillis: 0,
      pastStatuses: {
        '2024-03-22': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-21': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-20': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-19': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-18': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-17': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-16': {
          status: 'OK',
          newsletterSent: false,
        },
      },
    },
    {
      liveStatus: 'OK',
      applicationName: 'IT4EM LV',
      recentUptime: 100,
      recentDowntimeMillis: 0,
      pastStatuses: {
        '2024-03-22': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-21': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-20': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-19': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-18': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-17': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-16': {
          status: 'OK',
          newsletterSent: false,
        },
      },
    },
    {
      liveStatus: 'OK',
      applicationName: 'IT4EM SI',
      recentUptime: 100,
      recentDowntimeMillis: 0,
      pastStatuses: {
        '2024-03-22': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-21': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-20': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-19': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-18': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-17': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-16': {
          status: 'OK',
          newsletterSent: false,
        },
      },
    },
    {
      liveStatus: 'OK',
      applicationName: 'IT4EM SK',
      recentUptime: 100,
      recentDowntimeMillis: 0,
      pastStatuses: {
        '2024-03-22': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-21': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-20': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-19': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-18': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-17': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-16': {
          status: 'OK',
          newsletterSent: false,
        },
      },
    },
    {
      liveStatus: 'OK',
      applicationName: 'Lost&Found',
      recentUptime: 100,
      recentDowntimeMillis: 0,
      pastStatuses: {
        '2024-03-22': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-21': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-20': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-19': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-18': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-17': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-16': {
          status: 'OK',
          newsletterSent: false,
        },
      },
    },
    {
      liveStatus: 'OK',
      applicationName: 'Lost&Found (Back Office)',
      recentUptime: 100,
      recentDowntimeMillis: 0,
      pastStatuses: {
        '2024-03-22': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-21': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-20': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-19': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-18': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-17': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-16': {
          status: 'OK',
          newsletterSent: false,
        },
      },
    },
    {
      liveStatus: 'OK',
      applicationName: 'Meta API',
      recentUptime: 100,
      recentDowntimeMillis: 0,
      pastStatuses: {
        '2024-03-22': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-21': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-20': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-19': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-18': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-17': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-16': {
          status: 'OK',
          newsletterSent: false,
        },
      },
    },
    {
      liveStatus: 'OK',
      applicationName: 'Meta API Document',
      recentUptime: 100,
      recentDowntimeMillis: 0,
      pastStatuses: {
        '2024-03-22': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-21': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-20': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-19': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-18': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-17': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-16': {
          status: 'OK',
          newsletterSent: false,
        },
      },
    },
    {
      liveStatus: 'OK',
      applicationName: 'Meta API POD',
      recentUptime: 100,
      recentDowntimeMillis: 0,
      pastStatuses: {
        '2024-03-22': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-21': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-20': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-19': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-18': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-17': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-16': {
          status: 'OK',
          newsletterSent: false,
        },
      },
    },
    {
      liveStatus: 'OK',
      applicationName: 'Meta API Shipping',
      recentUptime: 100,
      recentDowntimeMillis: 0,
      pastStatuses: {
        '2024-03-22': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-21': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-20': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-19': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-18': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-17': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-16': {
          status: 'OK',
          newsletterSent: false,
        },
      },
    },
    {
      liveStatus: 'OK',
      applicationName: 'Meta API Tracking',
      recentUptime: 100,
      recentDowntimeMillis: 0,
      pastStatuses: {
        '2024-03-22': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-21': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-20': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-19': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-18': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-17': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-16': {
          status: 'OK',
          newsletterSent: false,
        },
      },
    },
    {
      liveStatus: 'OK',
      applicationName: 'Microstrategy',
      recentUptime: 100,
      recentDowntimeMillis: 0,
      pastStatuses: {
        '2024-03-22': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-21': {
          status: 'OUTAGE',
          newsletterSent: false,
        },
        '2024-03-20': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-19': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-18': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-17': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-16': {
          status: 'OK',
          newsletterSent: false,
        },
      },
    },
    {
      liveStatus: 'OK',
      applicationName: 'Mobile application',
      recentUptime: 100,
      recentDowntimeMillis: 0,
      pastStatuses: {
        '2024-03-22': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-21': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-20': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-19': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-18': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-17': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-16': {
          status: 'OK',
          newsletterSent: false,
        },
      },
    },
    {
      liveStatus: 'OK',
      applicationName: 'NICE',
      recentUptime: 100,
      recentDowntimeMillis: 0,
      pastStatuses: {
        '2024-03-22': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-21': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-20': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-19': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-18': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-17': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-16': {
          status: 'OK',
          newsletterSent: false,
        },
      },
    },
    {
      liveStatus: 'OK',
      applicationName: 'New Mobile application',
      recentUptime: 100,
      recentDowntimeMillis: 0,
      pastStatuses: {
        '2024-03-22': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-21': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-20': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-19': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-18': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-17': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-16': {
          status: 'OK',
          newsletterSent: false,
        },
      },
    },
    {
      liveStatus: 'OK',
      applicationName: 'RMPv4',
      recentUptime: 100,
      recentDowntimeMillis: 0,
      pastStatuses: {
        '2024-03-22': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-21': {
          status: 'OUTAGE',
          newsletterSent: true,
        },
        '2024-03-20': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-19': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-18': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-17': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-16': {
          status: 'OK',
          newsletterSent: false,
        },
      },
    },
    {
      liveStatus: 'OK',
      applicationName: 'RoadNet',
      recentUptime: 100,
      recentDowntimeMillis: 0,
      pastStatuses: {
        '2024-03-22': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-21': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-20': {
          status: 'OUTAGE',
          newsletterSent: true,
        },
        '2024-03-19': {
          status: 'OUTAGE',
          newsletterSent: true,
        },
        '2024-03-18': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-17': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-16': {
          status: 'OK',
          newsletterSent: false,
        },
      },
    },
    {
      liveStatus: 'OK',
      applicationName: 'SSO',
      recentUptime: 100,
      recentDowntimeMillis: 0,
      pastStatuses: {
        '2024-03-22': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-21': {
          status: 'OUTAGE',
          newsletterSent: true,
        },
        '2024-03-20': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-19': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-18': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-17': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-16': {
          status: 'OK',
          newsletterSent: false,
        },
      },
    },
    {
      liveStatus: 'OK',
      applicationName: 'Service Finder',
      recentUptime: 100,
      recentDowntimeMillis: 0,
      pastStatuses: {
        '2024-03-22': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-21': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-20': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-19': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-18': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-17': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-16': {
          status: 'OK',
          newsletterSent: false,
        },
      },
    },
    {
      liveStatus: 'OK',
      applicationName: 'Tableau',
      recentUptime: 100,
      recentDowntimeMillis: 0,
      pastStatuses: {
        '2024-03-22': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-21': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-20': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-19': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-18': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-17': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-16': {
          status: 'OK',
          newsletterSent: false,
        },
      },
    },
    {
      liveStatus: 'OK',
      applicationName: 'WISE CH',
      recentUptime: 100,
      recentDowntimeMillis: 0,
      pastStatuses: {
        '2024-03-22': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-21': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-20': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-19': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-18': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-17': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-16': {
          status: 'OK',
          newsletterSent: false,
        },
      },
    },
    {
      liveStatus: 'OK',
      applicationName: 'myDPD Internal',
      recentUptime: 100,
      recentDowntimeMillis: 0,
      pastStatuses: {
        '2024-03-22': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-21': {
          status: 'OUTAGE',
          newsletterSent: true,
        },
        '2024-03-20': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-19': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-18': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-17': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-16': {
          status: 'OK',
          newsletterSent: false,
        },
      },
    },
    {
      liveStatus: 'OK',
      applicationName: 'myDPD for Business',
      recentUptime: 100,
      recentDowntimeMillis: 0,
      pastStatuses: {
        '2024-03-22': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-21': {
          status: 'OUTAGE',
          newsletterSent: true,
        },
        '2024-03-20': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-19': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-18': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-17': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-16': {
          status: 'OK',
          newsletterSent: false,
        },
      },
    },
    {
      liveStatus: 'OK',
      applicationName: 'myDPD for Consignee',
      recentUptime: 100,
      recentDowntimeMillis: 0,
      pastStatuses: {
        '2024-03-22': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-21': {
          status: 'OUTAGE',
          newsletterSent: true,
        },
        '2024-03-20': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-19': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-18': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-17': {
          status: 'OK',
          newsletterSent: false,
        },
        '2024-03-16': {
          status: 'OK',
          newsletterSent: false,
        },
      },
    },
  ],
};
