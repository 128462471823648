import {
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useCallback } from 'react';
import { useApi } from '../../api/ApiProvider';
import { ApiResponse } from '../../api/types';

export type ConfigurationQuery = {
  id: number;
  startTime: string;
  endTime: string;
  countDowntime: boolean;
  totalDowntime: number;
  summary: string;
  rootCause: string;
  remediation: string;
  impact: string;
  jiraIssueURL: string;
  newsletterSent: boolean;
  active: boolean;
};

type QueryResult = ApiResponse<ConfigurationQuery>;

type UseConfigurationQueryParams = {
  incidentId: string | undefined | null;
};

export const createConfigurationCacheKey = (
  params: UseConfigurationQueryParams,
) => ['configuration', params];

export const useConfigurationQuery = (
  params: UseConfigurationQueryParams,
  opts?: UseQueryOptions<QueryResult, AxiosError>,
) => {
  const api = useApi();

  const { incidentId } = params;

  const queryFn = async ({ signal }: { signal?: AbortSignal }) => {
    const { data } = await api.get<QueryResult>(
      `/incident/details/${
        incidentId === 'null' || incidentId === null ? '0' : incidentId
      }`,
      {
        signal,
      },
    );
    return data;
  };

  const query = useQuery<QueryResult, AxiosError>(
    createConfigurationCacheKey(params),
    queryFn,
    opts,
  );

  return query;
};

export const useInvalidateConfigurationQuery = (
  params: UseConfigurationQueryParams,
) => {
  const queryClient = useQueryClient();

  const invalidate = useCallback(
    () => queryClient.invalidateQueries(createConfigurationCacheKey(params)),
    [params, queryClient],
  );

  return invalidate;
};
