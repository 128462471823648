import {ContactUsIcon} from '../icons/ContactUsIcon';
import {Link} from '@chakra-ui/react';

export const ContactUs = () => {
  return (
    <Link
      position="absolute"
      bottom={50}
      left={3}
      color="#414042"
      fontWeight="350"
      href="https://geopostgroup.atlassian.net/servicedesk/customer/portal/2"
    >
      <ContactUsIcon boxSize={9} /> Contact us!
    </Link>
  );
};
