import { useCallback } from 'react';
import { AxiosError } from 'axios';
import {
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';
import { useApi } from '../../api/ApiProvider';
import { ApiResponse } from '../../api/types';
import { ChartPeriod } from '../applicationMonitorPage/types';
import { ChartApp, ChartRecord } from './types';
import { TicketSupportLevel } from './ticketManagementDomain';

export type ChartDetails = {
  periods: ChartPeriod[];
  applications: ChartApp[];
  entries: ChartRecord[];
};

type QueryResult = ApiResponse<ChartDetails>;

type UseTicketManagementChartsQueryParams = {
  startPeriodValue: string | null;
  supportLevel: TicketSupportLevel | null;
};

export const createTicketManagementChartsCacheKey = (
  params: UseTicketManagementChartsQueryParams,
) => ['ticketManagementCharts', params];

export const useTicketManagementChartsQuery = (
  params: UseTicketManagementChartsQueryParams,
  opts?: UseQueryOptions<QueryResult, AxiosError>,
) => {
  const { startPeriodValue, supportLevel } = params;

  const api = useApi();

  const queryFn = async ({ signal }: { signal?: AbortSignal }) => {
    const { data } = await api.get<QueryResult>(
      `/ticket/management/chart?date=${startPeriodValue ?? ''}&supportLevel=${
        supportLevel ?? ''
      }`,
      {
        signal,
      },
    );
    return data;
  };

  const query = useQuery<QueryResult, AxiosError>(
    createTicketManagementChartsCacheKey(params),
    queryFn,
    {
      staleTime: 1000 * 60 * 5,
      ...opts,
    },
  );

  return query;
};

export const useInvalidateChartDetailsQuery = (
  params: UseTicketManagementChartsQueryParams,
) => {
  const queryClient = useQueryClient();

  const invalidate = useCallback(
    () =>
      queryClient.invalidateQueries(
        createTicketManagementChartsCacheKey(params),
      ),
    [params, queryClient],
  );

  return invalidate;
};
