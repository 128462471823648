export const appGroupNames = [
  'Portals',
  'Back office applications',
  'Customer applications',
  'IT4M',
];

export const appNames = [
  'CMS',
  'GeoShip',
  'myDPDBus',
  'myDPDInt',
  'Budah',
  'Duties',
  'GeoCheck',
  'NewGeoCheck',
  'NICE',
  'WPO',
  'RMP',
  'FMPv1',
  'DPDRegister',
  'IT4M',
  'MDU',
];

export const ticketTypes = ['INCIDENT', 'SERVICE_REQUEST'];

export const colors = [
  '#75678F',
  '#C06E94',
  '#F6958D',
  '#BFA5A3',
  '#DB7E1D',
  '#89C6D9',
  '#FFC004',
  '#A2B86D',
  '#bcf60c',
  '#fabebe',
  '#008080',
  '#e6beff',
  '#9a6324',
  '#f61385',
  '#800000',
  '#aaffc3',
  '#808000',
  '#ffd8b1',
  '#000075',
  '#808080',
  '#2c22ec',
  '#000000',
];
