import { Box, HStack, Spacer } from '@chakra-ui/react';
import { format } from 'date-fns';
import { FC, PropsWithChildren } from 'react';
import { Logo } from '../../ui-components/Header';

export const Header = () => (
  <HStack
    height="65px"
    paddingY={3}
    paddingX={8}
    borderBottom="solid 1px"
    borderBottomColor="gray.300"
    backgroundColor={'white'}
  >
    <Logo />
    <Spacer />
    <Box fontSize="21px">Availability monitor</Box>
    <Spacer />
    <Box fontSize="18px" color="gray.500">
      {format(new Date(), 'dd.MM.yyyy')}
    </Box>
  </HStack>
);

export const AppPage: FC<PropsWithChildren> = ({ children }) => (
  <Box height="100vh" width="100vw" overflowY="auto" backgroundColor="#F2F3F3">
    <Header />
    {children}
  </Box>
);
