import {
  Alert,
  AlertIcon,
  Button,
  Center,
  Heading,
  HStack,
  Stack,
  VStack,
  Text,
  Spinner,
  Box,
} from '@chakra-ui/react';
import { format } from 'date-fns';
import { FC, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useApplicationsTicketQuery } from '../queries/useApplicationsTicketQuery';
import { calculatePreviousEndedMonths } from '../TicketManagementPage';
import { TicketStatisticResolutionTimeChart } from './ticketStatisticTimeOverview/TicketStatisticResolutionTimeChart';
import { TicketStatisticResolutionTimeTable } from './ticketStatisticTimeOverview/TicketStatisticResolutionTimeTable';
import { TicketStatisticResolutionTimeTrendChart } from './ticketStatisticTimeOverview/TicketStatisticResolutionTimeTrendChart';
import { useResolutionTimeTableAndHoursChartQuery } from './ticketStatisticTimeOverview/useResolutionTimeTableAndHoursChartQuery';
import { useTicketStatisticsResolutionTimeTrendChartQuery } from './ticketStatisticTimeOverview/useTicketStatisticsResolutionTimeTrendChartQuery';

import { Select, FormControl, FormLabel, SelectProps } from '@dpd-ui/core';

type TicketStatisticTimeProps = {};

const lastTwelveMonths = calculatePreviousEndedMonths(12).map(
  ([currentYear, currentMonth]) =>
    `${currentYear}-${currentMonth.toString().padStart(2, '0')}-01`,
);

export const TicketStatisticTimeOverview: FC<TicketStatisticTimeProps> = () => {
  const [searchParams, setSearchParams] = useSearchParams({
    monthValue: lastTwelveMonths[0],
  });

  const paramsDetails = Object.fromEntries(searchParams);
  const { monthValue, selectedAppId } = paramsDetails;

  const applicationsQuery = useApplicationsTicketQuery(
    {},
    {
      params: { screen: 'TICKET_STATISTICS' },
    },
  );

  const tableEntriesQuery = useResolutionTimeTableAndHoursChartQuery({
    monthValue,
  });

  const trendChartQuery = useTicketStatisticsResolutionTimeTrendChartQuery({
    dateValue: lastTwelveMonths[0],
  });

  const tableAndChartData = useMemo(() => {
    const { data } = tableEntriesQuery;
    if (!data) return;
    if (!selectedAppId) return data;

    return {
      ...data,
      entries: data.entries.filter(
        ({ applicationId: id }) => id === selectedAppId,
      ),
    };
  }, [tableEntriesQuery, selectedAppId]);

  if (
    tableEntriesQuery.isError ||
    applicationsQuery.isError ||
    trendChartQuery.isError
  ) {
    return (
      <Center height="full" width="full">
        <Alert
          status="error"
          as={Stack}
          width="full"
          maxWidth="400px"
          borderRadius="lg"
          border="1px solid"
          borderColor="red.300"
        >
          <VStack>
            <AlertIcon />
            <Text>
              Error:
              {(tableEntriesQuery.error as Error).message ??
                'Something went wrong'}
            </Text>
          </VStack>
          <Button onClick={() => window.location.reload()}>
            Refresh the page
          </Button>
        </Alert>
      </Center>
    );
  }

  if (
    tableEntriesQuery.isLoading ||
    applicationsQuery.isLoading ||
    trendChartQuery.isLoading
  ) {
    return (
      <Center height="full" width="full">
        <Spinner />
      </Center>
    );
  }

  const trendChartData = trendChartQuery.data;
  const applications = applicationsQuery.data!;

  const setNewSearchParams = (obj: typeof paramsDetails) => {
    setSearchParams({
      ...paramsDetails,
      ...obj,
    });
  };

  const renderMonthFilter = () => {
    const options: SelectProps['options'] = lastTwelveMonths.map(
      (isoDate: string) => ({
        label: format(new Date(isoDate), 'MMMM yyy'),
        value: isoDate,
      }),
    );

    return (
      <FormControl>
        <Select
          options={options}
          value={monthValue}
          onChange={(value) => {
            value !== null && setNewSearchParams({ monthValue: value });
          }}
        />
        <FormLabel>Choose period</FormLabel>
      </FormControl>
    );
  };

  const renderAppsFilter = () => {
    const options: SelectProps['options'] = [
      { label: 'All apps', value: '' },
      ...Array.from(applications ?? [])
        .sort(({ name: a }, { name: b }) => {
          if (a.toLowerCase() === b.toLowerCase()) return 0;
          return a.toLowerCase() < b.toLowerCase() ? -1 : 1;
        })
        .map(({ name: label, id: value }) => ({
          label,
          value,
        })),
    ];

    return (
      <FormControl>
        <Select
          options={options}
          onChange={(value) => value !== null && setNewSearchParams({ selectedAppId: value })}
          value={selectedAppId ?? ''}
        />
        <FormLabel>Choose applications</FormLabel>
      </FormControl>
    );
  };

  return (
    <Stack flex={1} padding={5} spacing={6} width={0}>
      <Heading
        size="md"
        fontWeight="normal"
        color="#414042"
        fontSize="xl1"
        paddingBottom={5}
      >
        Average resolution time
      </Heading>

      <HStack maxWidth="50%">
        {renderMonthFilter()}
        {renderAppsFilter()}
      </HStack>

      <Stack flex={1} height={0} overflowY="auto" spacing={10}>
        <Stack
          direction={['column', null, null, null, 'row']}
          minHeight={['800px', null, null, null, '600px']}
          alignItems="stretch"
          spacing={5}
        >
          <Box flex={1}>
            <TicketStatisticResolutionTimeChart
              chartData={tableAndChartData!}
              monthValue={monthValue}
              applications={applications}
            />
          </Box>

          <Box flex={0.6}>
            <TicketStatisticResolutionTimeTrendChart data={trendChartData!} />
          </Box>
        </Stack>

        {tableEntriesQuery.isLoading ? (
          <Center height="full" width="full">
            <Spinner />
          </Center>
        ) : (
          <TicketStatisticResolutionTimeTable
            tableData={tableAndChartData!}
            applications={applications!}
          />
        )}
      </Stack>
    </Stack>
  );
};
