import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { BrowserRouter as Router } from 'react-router-dom';

import App from './App';
import { ChakraProvider } from '@chakra-ui/react';
import { theme } from './theme';
import { ApiProvider } from './api/ApiProvider';
import { startWorker } from './mocks/browser';
import { setupLocalDev } from './dev-local';
// import { setupInvalidToken } from './dev-local';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

(async () => {
  await startWorker();
  setupLocalDev();

  const root = ReactDOM.createRoot(document.getElementById('root')!);

  root.render(
    // TODO: need to find a way to overcome thishttps://github.com/react-keycloak/react-keycloak/issues/93#issuecomment-1108665029
    // <React.StrictMode>
    <Router>
      <ApiProvider>
        <QueryClientProvider client={queryClient}>
          <ChakraProvider theme={theme}>
            <App />
            <ReactQueryDevtools />
          </ChakraProvider>
        </QueryClientProvider>
      </ApiProvider>
    </Router>,
    // </React.StrictMode>,
  );
})();
