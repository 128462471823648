export const toggleElement = <T = unknown>(
  arr: T[],
  element: T,
  elementEq: (e: T) => boolean = (e) => e === element,
) => {
  if (arr.find(elementEq) !== undefined) {
    return arr.filter((el) => !elementEq(el));
  }

  return [...arr, element];
};
