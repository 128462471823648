import { useCallback } from 'react';
import { AxiosError } from 'axios';
import {
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';
import { useApi } from '../../../api/ApiProvider';
import { ApiResponse } from '../../../api/types';
import { ResTimeEntriesDetail } from '../../applicationMonitorPage/types';

export type ResolutionTimeAndChartData = {
  entries: ResTimeEntriesDetail[];
};

type QueryResult = ApiResponse<ResolutionTimeAndChartData>;

type UseTableAndChartQueryParams = {
  monthValue: string | null;
};

export const createResolutionTimeTableAndHoursChartCacheKey = (
  params: UseTableAndChartQueryParams,
) => ['resolutionTableAndHoursChartData', params];

export const useResolutionTimeTableAndHoursChartQuery = (
  params: UseTableAndChartQueryParams,
  opts?: UseQueryOptions<QueryResult, AxiosError>,
) => {
  const api = useApi();

  const { monthValue } = params;

  const queryFn = async ({ signal }: { signal?: AbortSignal }) => {
    const { data } = await api.get<QueryResult>(
      `/ticket/resolution-time?date=${monthValue ?? ''}`,
      {
        signal,
      },
    );
    return data;
  };

  const query = useQuery<QueryResult, AxiosError>(
    createResolutionTimeTableAndHoursChartCacheKey(params),
    queryFn,
    opts,
  );

  return query;
};

export const useInvalidateResolutionTimeTableAndHoursChartQuery = (
  params: UseTableAndChartQueryParams,
) => {
  const queryClient = useQueryClient();

  const invalidate = useCallback(
    () =>
      queryClient.invalidateQueries(
        createResolutionTimeTableAndHoursChartCacheKey(params),
      ),
    [params, queryClient],
  );

  return invalidate;
};
