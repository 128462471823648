import { Link } from 'react-router-dom';

import { HStack, Spacer, Text } from '@chakra-ui/react';
import { GeopostIcon } from '../icons/GeopostIcon';
import { configuration } from '../configuration';

export const Footer = () => {
  return (
    <HStack as="footer" paddingX={5} background="dpdGray.200" height="3rem">
      <Link to="/">
        <GeopostIcon height={'50px'} />
      </Link>

      <Spacer />

      <Text fontWeight="thin" fontSize="sm">
        {`Support Panel v${configuration.version}`}
      </Text>
    </HStack>
  );
};
