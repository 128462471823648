import { createIcon } from '@chakra-ui/react';

export const GraphIcon = createIcon({
  displayName: 'GraphIcon',
  viewBox: '0 0 36 36',
  path: [
    <path
      d="M9.26470588,29.8529412 L4.11764706,29.8529412 L4.11764706,17.5 L9.26470588,17.5 L9.26470588,29.8529412 Z M5.14705882,28.8235294 L8.23529412,28.8235294 L8.23529412,18.5294118 L5.14705882,18.5294118 L5.14705882,28.8235294 Z"
      id="Shape"
      fill="#414042"
      fillRule="nonzero"
    />,
    <path
      d="M16.4705882,29.8529412 L11.3235294,29.8529412 L11.3235294,13.3823529 L16.4705882,13.3823529 L16.4705882,29.8529412 Z M12.3529412,28.8235294 L15.4411765,28.8235294 L15.4411765,14.4117647 L12.3529412,14.4117647 L12.3529412,28.8235294 Z"
      id="Shape"
      fill="#414042"
      fillRule="nonzero"
    />,
    <path
      d="M23.6764706,29.8529412 L18.5294118,29.8529412 L18.5294118,14.4117647 L23.6764706,14.4117647 L23.6764706,29.8529412 Z M19.5588235,28.8235294 L22.6470588,28.8235294 L22.6470588,15.4411765 L19.5588235,15.4411765 L19.5588235,28.8235294 Z"
      id="Shape"
      fill="#414042"
      fillRule="nonzero"
    />,
    <path
      d="M30.8823529,29.8529412 L25.7352941,29.8529412 L25.7352941,11.3235294 L30.8823529,11.3235294 L30.8823529,29.8529412 Z M26.7647059,28.8235294 L29.8529412,28.8235294 L29.8529412,12.3529412 L26.7647059,12.3529412 L26.7647059,28.8235294 Z"
      id="Shape"
      fill="#414042"
      fillRule="nonzero"
    />,
    <path
      d="M4.63235294,15.4411765 C4.40302086,15.4396294 4.20237872,15.2865406 4.14032122,15.0657591 C4.07826372,14.8449776 4.16976396,14.6097731 4.36470588,14.4889706 L13.8404412,8.69852941 L21.0463235,11.2720588 L30.0845588,5.23455882 C30.3187634,5.10892976 30.610106,5.18227241 30.756913,5.40381743 C30.9037199,5.62536244 30.8577113,5.92225105 30.6507353,6.08897059 L21.175,12.4095588 L13.9691176,9.83602941 L4.9,15.3639706 C4.81957658,15.4138175 4.72696912,15.4405312 4.63235294,15.4411765 Z"
      id="Path"
      fill="#DC0032"
      fillRule="nonzero"
    />,
  ],
});
