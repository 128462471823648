import { createIcon } from '@chakra-ui/react';

export const ConfigIcon = createIcon({
  displayName: 'ConfigIcon',
  viewBox: '0 0 36 36',
  path: [
    <path
      d="M17.5 26.569c-4.54 0-8.235-3.695-8.235-8.236 0-4.54 3.694-8.235 8.235-8.235 4.54 0 8.235 3.695 8.235 8.235 0 4.541-3.694 8.236-8.235 8.236Zm0-15.442c-3.974 0-7.206 3.233-7.206 7.206 0 3.974 3.232 7.206 7.206 7.206s7.206-3.232 7.206-7.206c0-3.973-3.232-7.206-7.206-7.206Z"
      fill="#414042"
    />,
    <path
      d="M17.5 23.995a5.668 5.668 0 0 1-5.662-5.662 5.668 5.668 0 0 1 5.662-5.661 5.668 5.668 0 0 1 5.662 5.661 5.668 5.668 0 0 1-5.662 5.662Zm0-10.294a4.638 4.638 0 0 0-4.632 4.632 4.638 4.638 0 0 0 4.632 4.633 4.637 4.637 0 0 0 4.632-4.633 4.638 4.638 0 0 0-4.632-4.632Z"
      fill="#DC0032"
    />,
    <path d="M19.43 9.393h-.004a.963.963 0 0 0-.862-.985 10.72 10.72 0 0 0-2.132.013h-.05a.97.97 0 0 0-.863.972v1.22h3.907l.004-1.22ZM26.44 20.29v-.005a.963.963 0 0 0 .985-.862 10.72 10.72 0 0 0-.013-2.131v-.051a.97.97 0 0 0-.972-.863h-1.22v3.907l1.22.004ZM15.583 27.274h.005a.963.963 0 0 0 .861.984 10.72 10.72 0 0 0 2.132-.012h.05a.97.97 0 0 0 .863-.972v-1.22h-3.906l-.005 1.22ZM12.546 10.646l-.004.004a.963.963 0 0 0-1.305-.087 10.72 10.72 0 0 0-1.498 1.516l-.036.036a.97.97 0 0 0 .077 1.297l.863.863 2.762-2.763-.859-.866ZM25.144 13.385l-.004-.004a.963.963 0 0 0 .087-1.305 10.72 10.72 0 0 0-1.516-1.498l-.036-.036a.97.97 0 0 0-1.297.077l-.863.863 2.763 2.762.866-.859ZM22.43 25.996l.003-.003a.963.963 0 0 0 1.305.087 10.72 10.72 0 0 0 1.499-1.517l.036-.036a.97.97 0 0 0-.078-1.297l-.862-.862-2.763 2.762.86.866ZM9.842 23.265l.003.003a.963.963 0 0 0-.087 1.306c.45.541.975 1.066 1.517 1.498l.036.036a.97.97 0 0 0 1.297-.077l.862-.863-2.762-2.762-.866.859ZM8.559 16.416v.004a.963.963 0 0 0-.985.862 10.72 10.72 0 0 0 .013 2.131v.051a.97.97 0 0 0 .972.863h1.22V16.42l-1.22-.004Z" />,
  ],
});
