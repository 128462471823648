import { Center } from '@chakra-ui/react';
import { FC } from 'react';
import { AppPage } from '../ui-components/AppPage';

type NoPermissionsPageProps = {};

export const NoPermissionsPage: FC<NoPermissionsPageProps> = () => {
  return (
    <AppPage>
      <Center height="full" width="full">
        {`You do not have permission to view this page`}
      </Center>
    </AppPage>
  );
};
