import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { ApplicationMonitorPage } from './pages/ApplicationMonitorPage';
import { ConfigurationPage } from './pages/ConfigurationPage';
import { DashboardPage } from './pages/DashboardPage';
import { NoPermissionsPage } from './pages/NoPermissionsPage';
import { TicketManagementPage } from './pages/TicketManagementPage';
import { TicketStatisticPage } from './pages/TicketStatisticPage';

import {
  PermissionsProvider,
  ProtectedResource,
} from './providers/PermissionProvider';

import { AwaitAuth } from './ui-components/AwaitAuth';
import { AppPage } from './ui-components/AppPage';
import { Box } from '@chakra-ui/react';
import { FC } from 'react';
import { AuthProvider } from './providers/AuthProvider';

const SupportPanelApp: FC = () => {
  return (
    <Box
      // "TODO: need decisions if we need provide there some props to hide this button - Select component"
      sx={{
        '.chakra-form-control button[aria-label=Close]': {
          display: 'none',
        },
      }}
    >
      <AwaitAuth>
        <PermissionsProvider fallbackRoute="/no-permissions">
          <Routes>
            <Route index element={<Navigate to={'/monitor/availability'} />} />
            <Route path="monitor" element={<Outlet />}>
              <Route
                path="availability/*"
                element={
                  <ProtectedResource
                    shouldRedirect
                    permissionsFor="ApplicationMonitor"
                  >
                    <ApplicationMonitorPage />
                  </ProtectedResource>
                }
              />
              <Route index element={<Navigate to="availability" />} />
            </Route>

            <Route
              path="ticket-management"
              element={
                <ProtectedResource
                  shouldRedirect
                  permissionsFor="TicketManagement"
                >
                  <AppPage>
                    <TicketManagementPage />
                  </AppPage>
                </ProtectedResource>
              }
            />

            <Route
              path="ticket-statistics/*"
              element={
                <ProtectedResource
                  shouldRedirect
                  permissionsFor="TicketStatistic"
                >
                  <TicketStatisticPage />
                </ProtectedResource>
              }
            />

            <Route
              path="configuration/*"
              element={
                <ProtectedResource
                  shouldRedirect
                  permissionsFor="Configuration"
                >
                  <ConfigurationPage />
                </ProtectedResource>
              }
            />

            <Route path="no-permissions" element={<NoPermissionsPage />} />
          </Routes>
        </PermissionsProvider>
      </AwaitAuth>
    </Box>
  );
};

const DashboardApp: FC = () => {
  return (
    <Routes>
      <Route path="/dashboard" element={<DashboardPage />} />
    </Routes>
  );
};

export const App = () => {
  const { pathname } = window.location;

  if (pathname.startsWith('/dashboard')) {
    return <DashboardApp />;
  }

  return (
    <AuthProvider>
      <SupportPanelApp />
    </AuthProvider>
  );
};

export default App;
