import { FC, useCallback } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { AppPage } from '../ui-components/AppPage';
import { ConfigurationOverview } from './configurationPage/ConfigurationOverview';

export const createIncidentConfigPath = ({
  appIdSegment,
  incidentIdSegment,
}: Record<'appIdSegment' | 'incidentIdSegment', string | undefined | null>) =>
  `?incidentId=${incidentIdSegment}&appId=${appIdSegment}`;

type ConfigurationPageProps = {};
export const ConfigurationPage: FC<ConfigurationPageProps> = () => {
  const navigate = useNavigate();

  const onStatusClick = useCallback(
    (args: { appId: string; incidentId: string | undefined | null }) => {
      const { appId, incidentId } = args;

      navigate(
        createIncidentConfigPath({
          appIdSegment: appId,
          incidentIdSegment: incidentId,
        }),
      );
    },
    [navigate],
  );

  return (
    <AppPage>
      <Routes>
        <Route
          index
          element={<ConfigurationOverview onStatusClick={onStatusClick} />}
        />
        <Route
          path="/incident-new"
          element={<ConfigurationOverview onStatusClick={onStatusClick} />}
        />
        <Route
          path={createIncidentConfigPath({
            incidentIdSegment: ':incidentId',
            appIdSegment: ':appId',
          })}
          element={<ConfigurationOverview onStatusClick={onStatusClick} />}
        />
      </Routes>
    </AppPage>
  );
};
