import { createIcon } from '@chakra-ui/react';

export const TicketStatisticsIcon = createIcon({
  displayName: 'TicketStatisticsIcon',
  viewBox: '0 0 35 35',
  path: [
    <path
      d="M26.2499 25.7353H18.5294C18.2447 25.7353 18.0146 25.5053 18.0146 25.2206C18.0146 24.936 18.2447 24.7059 18.5294 24.7059H26.2499C26.5346 24.7059 26.7646 24.936 26.7646 25.2206C26.7646 25.5053 26.5346 25.7353 26.2499 25.7353Z"
      fill="#414042"
    />,
    <path
      d="M26.2501 25.7353C25.9654 25.7353 25.7354 25.5052 25.7354 25.2206V19.0441C25.7354 18.7595 25.9654 18.5294 26.2501 18.5294C26.5347 18.5294 26.7648 18.7595 26.7648 19.0441V25.2206C26.7648 25.5052 26.5347 25.7353 26.2501 25.7353Z"
      fill="#414042"
    />,
    <path
      d="M17.4999 11.3235H5.66168C5.37705 11.3235 5.14697 11.0934 5.14697 10.8088C5.14697 10.5241 5.37705 10.2941 5.66168 10.2941H17.4999C17.7845 10.2941 18.0146 10.5241 18.0146 10.8088C18.0146 11.0934 17.7845 11.3235 17.4999 11.3235Z"
      fill="#414042"
    />,
    <path
      d="M5.66168 25.7352C5.37705 25.7352 5.14697 25.5052 5.14697 25.2205V10.8088C5.14697 10.5241 5.37705 10.2941 5.66168 10.2941C5.94631 10.2941 6.17638 10.5241 6.17638 10.8088V25.2205C6.17638 25.5052 5.94631 25.7352 5.66168 25.7352Z"
      fill="#414042"
    />,
    <path
      d="M13.3859 25.7353H5.66168C5.37705 25.7353 5.14697 25.5053 5.14697 25.2206C5.14697 24.936 5.37705 24.7059 5.66168 24.7059H13.3859C13.6705 24.7059 13.9006 24.936 13.9006 25.2206C13.9006 25.5053 13.6705 25.7353 13.3859 25.7353Z"
      fill="#414042"
    />,
    <path
      d="M21.6175 28.8235H10.294C10.0094 28.8235 9.7793 28.5934 9.7793 28.3088C9.7793 28.0241 10.0094 27.7941 10.294 27.7941H21.6175C21.9022 27.7941 22.1322 28.0241 22.1322 28.3088C22.1322 28.5934 21.9022 28.8235 21.6175 28.8235Z"
      fill="#DC0032"
    />,
    <path
      d="M31.9116 17.5H18.5293V6.17651H31.9116V17.5ZM19.5587 16.4706H30.8822V7.20593H19.5587V16.4706Z"
      fill="#414042"
    />,
    <path
      d="M31.3969 9.26476H19.044C18.7594 9.26476 18.5293 9.03469 18.5293 8.75006C18.5293 8.46543 18.7594 8.23535 19.044 8.23535H31.3969C31.6816 8.23535 31.9116 8.46543 31.9116 8.75006C31.9116 9.03469 31.6816 9.26476 31.3969 9.26476Z"
      fill="#414042"
    />,
    <path
      d="M29.3381 11.3235H21.1028C20.8182 11.3235 20.5881 11.0934 20.5881 10.8088C20.5881 10.5241 20.8182 10.2941 21.1028 10.2941H29.3381C29.6228 10.2941 29.8528 10.5241 29.8528 10.8088C29.8528 11.0934 29.6228 11.3235 29.3381 11.3235Z"
      fill="#DC0032"
    />,
    <path
      d="M24.1911 13.3823H21.1028C20.8182 13.3823 20.5881 13.1522 20.5881 12.8676C20.5881 12.583 20.8182 12.3529 21.1028 12.3529H24.1911C24.4757 12.3529 24.7058 12.583 24.7058 12.8676C24.7058 13.1522 24.4757 13.3823 24.1911 13.3823Z"
      fill="#DC0032"
    />,
  ],
});
