import {Box, Flex, Stack, Text} from '@chakra-ui/react';
import {format} from 'date-fns';
import {FC, useMemo} from 'react';
import {useMeasure} from 'react-use';
import {Bar, BarChart, CartesianGrid, LabelList, Legend, ResponsiveContainer, XAxis, YAxis,} from 'recharts';
import {TicketStatisticsIncidentsGraphData} from './useTicketStatisticsTableAndIncidentsQuery';

type TicketStatisticsIncidentGraphProps = {
  data: TicketStatisticsIncidentsGraphData;
  monthValue: string;
  showThirdPartyCause: boolean;
};

export const TicketStatisticsIncidentsGraph: FC<
  TicketStatisticsIncidentGraphProps
> = (props) => {
  const { data, monthValue, showThirdPartyCause } = props;

  const [chartContainerRef, chartContainerDimens] =
    useMeasure<HTMLDivElement>();

  const appsIncident = data.tickets;

  const graphData = useMemo(() => {
    const filteredApps = appsIncident.filter(
      ({ blocker, critical, blockerThirdParty, criticalThirdParty }) =>
          showThirdPartyCause ? (blockerThirdParty !== 0 || criticalThirdParty !== 0) : (blocker !== 0 || critical !== 0),
    );

    return filteredApps
      .sort(({ name: a }, { name: b }) => {
        if (a.toLowerCase() === b.toLowerCase()) return 0;
        return a.toLowerCase() > b.toLowerCase() ? 1 : -1;
      })
      .map((appData) => {
          const {
              id,
              name,
              blocker,
              critical,
              blockerThirdParty,
              criticalThirdParty,
          } = appData;

        return {
          dateTime: format(new Date(monthValue), 'MMMM yyyy'),
          name,
          id,
          blocker: blocker > 0 ? blocker : null,
          critical: critical > 0 ? critical : null,
            blockerThirdParty: blockerThirdParty > 0 ? blockerThirdParty : null,
            criticalThirdParty:
                criticalThirdParty > 0 ? criticalThirdParty : null,
        };
      });
  }, [appsIncident, monthValue]);

  return (
    <Stack height="full">
      <Stack marginTop={10} border="1px solid #e6e7e8" padding={0} flex={1}>
        <Flex
          backgroundColor="#e6e7e8"
          textAlign="end"
          width="full"
          paddingLeft={4}
          paddingRight={4}
          paddingTop={3}
          paddingBottom={3}
          justifyContent="space-between"
        >
          <Text color="#414042">Blocker and critical incidents</Text>
          <Text color="#808285">
            {format(new Date(monthValue), 'MMMM yyyy')}
          </Text>
        </Flex>

        <Box ref={chartContainerRef} flex={1} width="full">
          <ResponsiveContainer
            width={chartContainerDimens.width}
            height={chartContainerDimens.height}
          >
            <BarChart
              barSize={50}
              data={graphData}
              margin={{ top: 20, bottom: 5, right: 30 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="name"
                domain={['0', '0']}
                tickMargin={10}
                textAnchor="end"
                fontSize={12}
                interval={0}
                angle={300}
                height={140}
              />
              <YAxis tickMargin={10} fontSize={12} interval={0} />
              <Legend
                layout="vertical"
                align="right"
                verticalAlign="middle"
                iconType="circle"
                iconSize={20}
                wrapperStyle={{ right: '0' }}
              />
              <Bar
                dataKey={showThirdPartyCause ? "blockerThirdParty" : "blocker"}
                name="blocker"
                fill="rgba(220, 0, 50, 1)"
                stackId="a"
                barSize={30}
              >
                <LabelList
                  dataKey={showThirdPartyCause ? "blockerThirdParty" : "blocker"}
                  position="insideBottom"
                  style={{ fill: '#ffffff', fontSize: '0.6rem' }}
                />
              </Bar>
              <Bar dataKey={showThirdPartyCause ? "criticalThirdParty" : "critical"} name="critical" fill="#ffc004" stackId="a">
                <LabelList
                  dataKey={showThirdPartyCause ? "criticalThirdParty" : "critical"}
                  position="insideBottom"
                  style={{ fontSize: '0.6rem' }}
                />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </Box>
      </Stack>
    </Stack>
  );
};
