import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Spinner,
  Stack,
  Text,
  Wrap,
} from '@chakra-ui/react';
import { FC, useState } from 'react';
import { BsPlus } from 'react-icons/bs';
import { useNavigate, useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';

import { InfoIcon } from '../../icons/InfoIcon';
import { StatisticSummary } from '../../ui-components/StatisticSummary';
import { useApplicationsQuery } from './useApplicationsQuery';
import { format, isValid } from 'date-fns';
import { useIncidentDetailsQuery } from './useIncidentDetailsQuery';
import { IncidentsTable } from './incidentOverview/IncidentsTable';
import { createIncidentDetailsPath } from '../ApplicationMonitorPage';

import { PeriodType } from './types';
import { SuccessIcon } from '../../icons/SuccessIcon';
import { ErrorIcon } from '../../icons/ErrorIcon';
import { useDeleteIncidentMutation } from './incidentOverview/useDeleteIncidentMutation';

export type IncidentOverviewProps = {};

export const IncidentOverview: FC<IncidentOverviewProps> = (props) => {
  const navigate = useNavigate();

  const params = useParams<{
    appId: string;
    periodType: PeriodType;
    periodValue: string;
  }>();

  const mutateParams = (newParams: Partial<typeof params>) => {
    const all = { ...params, ...newParams } as Required<typeof params>;

    navigate(
      '/monitor/availability/' +
        createIncidentDetailsPath({
          appIdSegment: all.appId,
          periodTypeSegment: all.periodType,
          periodValueSegment: all.periodValue,
        }),
    );
  };

  const { appId, periodType, periodValue } = params;

  invariant(appId, 'appId path segment is missing');
  invariant(periodType, 'periodType path segment is missing');
  invariant(periodValue, 'periodValue path segment is missing');

  const applicationsQuery = useApplicationsQuery(
    {},
    { params: { screen: 'AVAILABILITY_MONITOR' } },
  );

  const incidentDetailsQuery = useIncidentDetailsQuery({
    appId,
    periodType,
    periodValue: format(new Date(periodValue), "yyyy-MM-dd'T'HH:mm"),
  });

  const [incidentIdToRemove, setIncidentIdToRemove] = useState<string | null>(
    null,
  );
  const deleteIncidentMutation = useDeleteIncidentMutation({
    onSuccess: () => {
      setIncidentIdToRemove(null);
      incidentDetailsQuery.refetch();
    },
  });

  if (applicationsQuery.isError || incidentDetailsQuery.isError) {
    return (
      <Center height="full" width="full">
        <Alert
          status="error"
          as={Stack}
          width="90%"
          maxWidth="400px"
          borderRadius="lg"
          border="1px solid"
          borderColor="red.300"
        >
          <HStack>
            <AlertIcon />
            <Text>
              Error:{' '}
              {(
                applicationsQuery.error || (incidentDetailsQuery.error as Error)
              )?.message ?? 'Smth went wrong'}
            </Text>
          </HStack>
          <Button onClick={() => window.location.reload()}>
            Refresh the page
          </Button>
        </Alert>
      </Center>
    );
  }

  if (applicationsQuery.isLoading || incidentDetailsQuery.isLoading) {
    return (
      <Center height="full" width="full">
        <Spinner />
      </Center>
    );
  }

  const applications = applicationsQuery.data!;
  const incidentDetails = incidentDetailsQuery.data!;

  const incidentCount = incidentDetails.numberOfIncidents;

  const renderStats = () => {
    const summaryPercent = incidentDetails.totalUptimePercent;

    const renderStatusIcon = () => {
      if (Number(summaryPercent) >= 99.5) {
        return <SuccessIcon fill="#509e2f" boxSize={5} />;
      }

      if (Number(summaryPercent) >= 99.0) {
        return <InfoIcon fill="#ffc004" boxSize={5} />;
      }

      return <ErrorIcon fill="#dc0032" boxSize={5} />;
    };

    return (
      <>
        <StatisticSummary
          flex={1}
          iconLeft={renderStatusIcon()}
          label="Uptime"
          summary={`${incidentDetails.totalUptimePercent}%`}
        />

        <StatisticSummary
          flex={1}
          iconLeft={<InfoIcon fill="#ffc004" boxSize={5} />}
          label="Incidents summary"
          summary={`${incidentCount} incident${incidentCount === 1 ? '' : 's'}`}
        />
      </>
    );
  };

  const renderAppsFilterInput = () => {
    return (
      <FormControl flex={1}>
        <FormLabel as="legend" fontSize="xs" fontWeight="normal">
          Choose applications
        </FormLabel>
        <Select
          borderRadius="none"
          border="1px solid"
          borderColor="black"
          size="lg"
          fontSize="0.875rem"
          value={appId}
          onChange={({ currentTarget: { value } }) => {
            mutateParams({ appId: value });
          }}
        >
          {applications.map(({ name, id }) => {
            return (
              <option key={id} value={id}>
                {name}
              </option>
            );
          })}
        </Select>
      </FormControl>
    );
  };

  const renderCustomPeriodInput = () => {
    const value =
      periodType === 'month'
        ? format(new Date(periodValue!), 'yyyy-MM')
        : format(new Date(periodValue!), 'yyyy-MM-dd');

    return (
      <FormControl flex={1}>
        <FormLabel as="legend" fontSize="xs" fontWeight="normal">
          Choose time period
        </FormLabel>
        <Input
          key={value}
          type={periodType === 'month' ? 'month' : 'date'}
          borderRadius="none"
          border="1px solid"
          borderColor="black"
          size="lg"
          fontSize="0.875rem"
          defaultValue={value}
          onChange={({ target: { value } }) => {
            const asDate = new Date(value);

            if (isValid(asDate)) {
              mutateParams({
                periodValue: value,
              });
            }
          }}
        />
      </FormControl>
    );
  };

  const renderPeriodsInput = () => {
    return (
      <FormControl flex={1}>
        <FormLabel as="legend" fontSize="xs" fontWeight="normal">
          Switch view
        </FormLabel>
        <Select
          borderRadius="none"
          border="1px solid"
          borderColor="black"
          size="lg"
          fontSize="0.875rem"
          value={periodType}
          onChange={({ currentTarget: { value } }) => {
            mutateParams({
              periodType: value as PeriodType,
            });
          }}
        >
          <option value="day">Daily view</option>
          <option value="month">Monthly view</option>
        </Select>
      </FormControl>
    );
  };

  const selectedApp = applications.find(({ id }) => id === appId);

  const periodLabel = format(
    new Date(periodValue!),
    periodType === 'day' ? 'dd.MM.yyyy' : 'MM.yyyy',
  );

  if (!selectedApp) {
    return (
      <Center
        as={Stack}
        flex={1}
        padding={3}
        spacing={8}
        width={0}
        height="full"
      >
        <Heading>Application not found</Heading>
        <Button onClick={() => navigate('/monitor/availability')}>
          Return to home
        </Button>
      </Center>
    );
  }

  const renderModal = () => {
    if (incidentIdToRemove === null) {
      return null;
    }

    const exitModal = () => {
      deleteIncidentMutation.reset();
      setIncidentIdToRemove(null);
    };

    const deleteIncident = () =>
      deleteIncidentMutation.mutate({
        incidentId: incidentIdToRemove!,
      });

    return (
      <Modal
        isOpen
        onClose={() => exitModal()}
        isCentered
        closeOnEsc={!deleteIncidentMutation.isLoading}
        closeOnOverlayClick={!deleteIncidentMutation.isLoading}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm delete</ModalHeader>

          <ModalCloseButton isDisabled={deleteIncidentMutation.isLoading} />

          <ModalBody>
            <Stack>
              <Text>{`Are you sure you want to delete the incident?`}</Text>

              {deleteIncidentMutation.isError && (
                <Alert
                  status="error"
                  as={Stack}
                  borderRadius="lg"
                  border="1px solid"
                  borderColor="red.300"
                >
                  <HStack>
                    <AlertIcon />
                    <Text>
                      {`Error: ${
                        deleteIncidentMutation.error?.message ??
                        'Something went wrong'
                      }`}
                    </Text>
                    <Button
                      onClick={() => deleteIncident()}
                      borderRadius={0}
                      isLoading={deleteIncidentMutation.isLoading}
                    >
                      Retry
                    </Button>
                  </HStack>
                </Alert>
              )}
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button
              color="#ffffff"
              backgroundColor="#808285"
              size="sm"
              borderRadius={0}
              fontWeight="normal"
              mr={3}
              onClick={() => exitModal()}
              paddingX={6}
              paddingY={5}
              isDisabled={deleteIncidentMutation.isLoading}
            >
              Close
            </Button>

            <Button
              onClick={() => deleteIncident()}
              isLoading={deleteIncidentMutation.isLoading}
              loadingText="Loading, please wait…"
              color="#ffffff"
              backgroundColor="#dc0032"
              size="sm"
              borderRadius={0}
              fontWeight="normal"
              paddingX={6}
              paddingY={5}
            >
              Confirm
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  };

  return (
    <Stack flex={1} padding={5} spacing={8} width={0} height="full">
      <Heading size="md" fontWeight="medium" color="#414042" fontSize="xl">
        {selectedApp.name}
      </Heading>

      <Wrap spacing={5} align="flex-end">
        {renderStats()}
        {renderAppsFilterInput()}
        {renderPeriodsInput()}
        {renderCustomPeriodInput()}
      </Wrap>

      <Stack border="1px solid #e6e7e8" flex={1} height={0}>
        <Stack padding={5} spacing={5} flex={1} overflowY="auto">
          <Flex justify="space-between">
            <Heading as="h5" size="md" fontWeight="medium">
              Incidents
            </Heading>

            <Text fontSize="md" color="#808285">
              {periodLabel}
            </Text>
          </Flex>

          <Flex
            justifyContent="flex-end"
            width="full"
            align="center"
            marginTop={0}
          >
            <Box
              display="flex"
              alignItems="center"
              onClick={() => navigate('/configuration/incident-new')}
              cursor="pointer"
            >
              <BsPlus color="red" size="2em" />
              <Text fontWeight="normal" color="#414042" fontSize="sm">
                Add incident
              </Text>
            </Box>
          </Flex>

          <Stack flex={1} height={0} overflowY="auto">
            <IncidentsTable
              incidents={incidentDetails.incidents}
              appId={appId}
              onDeleteIncident={(incidentId) =>
                setIncidentIdToRemove(incidentId)
              }
            />
            {renderModal()}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
