import { createIcon } from '@chakra-ui/react';

export const PencilIcon = createIcon({
  displayName: 'PencilIcon',
  viewBox: '-1 -1 33 33',
  path: [
    <path
      d="M9.229 24.832a.441.441 0 0 1-.312-.753l12.79-12.79-2.496-2.496L7.67 20.335a.441.441 0 1 1-.624-.623L19.211 7.545l3.744 3.743L9.541 24.703a.44.44 0 0 1-.312.129Z"
      fill="#414042"
    />,
    <path
      d="M9.257 22.365a.44.44 0 0 1-.312-.13l-1.9-1.9a.441.441 0 0 1 .624-.623l1.9 1.9a.441.441 0 0 1-.312.752ZM5.174 25.768a.442.442 0 0 1-.43-.54l.935-4.056a.441.441 0 1 1 .86.198l-.936 4.056a.441.441 0 0 1-.43.342Z"
      fill="#414042"
    />,
    <path
      d="M5.173 25.768a.441.441 0 0 1-.312-.753l1.248-1.248a.441.441 0 0 1 .624.624l-1.248 1.248a.44.44 0 0 1-.312.129Z"
      fill="#414042"
    />,
    <path
      d="M5.173 25.768a.441.441 0 0 1-.099-.871l4.056-.936a.441.441 0 0 1 .198.86l-4.056.936a.44.44 0 0 1-.1.011Z"
      fill="#414042"
    />,
    <path
      d="M23.579 10.482a.441.441 0 0 1-.312-.753l.936-.936-2.496-2.496-.936.936a.441.441 0 0 1-.624-.624l1.56-1.56 3.744 3.744-1.56 1.56a.44.44 0 0 1-.312.129Z"
      fill="#DC0032"
    />,
  ],
});
