import { AuthSSOProvider, useAuthSSO } from './authProvider/AuthSSOProvider';
import {
  AuthCredentialsProvider,
  useAuthCredentials,
} from './authProvider/AuthCredentialsProvider';
import { configuration } from '../configuration';

const { environment } = configuration;

export const AuthProvider =
  environment === 'prod' ? AuthSSOProvider : AuthCredentialsProvider;

export const useAuth = environment === 'prod' ? useAuthSSO : useAuthCredentials;
