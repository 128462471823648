import { context, RestRequest } from 'msw';

export const getIncidentsDetails = (req: RestRequest) =>
  context.json({
    totalUptimePercent: '98.25',
    totalDowntimeMillis: Math.random() * 50 * 1000,
    numberOfIncidents: Math.round(Math.random() * 10),
    incidents: Object.fromEntries(
      Array.from({ length: 10 }).map((_, i) => [
        i,
        {
          endTime: `2022-08-${20 - i}T10:46:28+0000`,
          impact: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar tempor.`,
          jiraIssueURL: 'https://jira.dpdgroup.com/browse/geoShip-124',
          remediation: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar tempor.`,
          rootCause: 'Server did not response or cokolwiek',
          startTime: `2022-08-${20 - i}T10:46:28+0000`,
          summary: `WPO2 has faced an issue which cause 10 min distruption. This is not the level of quality and reliability we strive to offer you and we are taking immediate steps to improve the platform’s performance and availability.`,
          id: String(Math.floor(Math.random() * 100) + 1),
          countDowntime: true,
          newsletterSent: Date.now() % 2 === 0,
          totalDowntime: i % 3 === 0 ? null : Math.random() * 100 * 1000,
        },
      ]),
    ),
  });

const SEVERITY = ['BLOCKER', 'CRITICAL', null];

export const getIncidentsPerApplication = (req: RestRequest) =>
  context.json(
    Array.from({ length: 10 }).map((_, i) => ({
      applicationId: String(i),
      severity: SEVERITY[i % SEVERITY.length],
      startDateTime: `2022-08-${20 - i}T10:46:28+0000`,
      endDateTime: `2022-08-${20 - i}T10:46:28+0000`,
      jiraUrl: 'https://jira.dpdgroup.com/browse/geoShip-124',
    })),
  );
