import { context } from 'msw';
import { randomElement, randomNumber } from '../../utils/mockHelpers';

export const response = () => {
  const periodCount = 6;
  const incidentName = [
    'BLOCKER',
    'CRITICAL',
  ];

  const severities = incidentName.map((severity, index) => {
        return {
          id: String(index),
          severity,
          thirdPartyCause: false
        }})

  const response = {
    severities,

    periods: Array.from({ length: periodCount }).map((_, index) => {
      const currentYear = new Date().getFullYear();
      const currentMonth = (index + 1) % 12;

      return {
        id: String(index),
        dateTime: `${currentYear}-${currentMonth.toString().padStart(2, '0')}`,
      };
    }),

    entries: (() => {
      let result = [];

      for (let periodId = 0; periodId < periodCount; periodId++) {
        for (let id = 0; id < severities.length; id++) {
          result.push({
            periodId: String(periodId),
            severityId: String(id),
            averageResolutionTime: randomElement([
              randomNumber(5 * 1000 * 60 * 60, 0.5 * 1000 * 60 * 60),
            ]),
          });
        }
      }
      return result;
    })(),
  };

  return context.json(response);
};
