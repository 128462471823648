import { useCallback } from 'react';
import { AxiosError } from 'axios';
import {
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';
import { useApi } from '../../api/ApiProvider';
import { ApiResponse } from '../../api/types';
import { Entry } from './types';

export type DashboardData = {
  entries: Entry[];
};

type QueryResult = ApiResponse<DashboardData>;

export const createDashboardDataCacheKey = () => ['dashboard'];

export const useDashboardDataQuery = (
  opts?: UseQueryOptions<QueryResult, AxiosError>,
) => {
  const api = useApi();

  const queryFn = async ({ signal }: { signal?: AbortSignal }) => {
    const { data } = await api.get<QueryResult>(`/dashboard`, {
      signal,
    });

    return data;
  };

  const query = useQuery<QueryResult, AxiosError>(
    createDashboardDataCacheKey(),
    queryFn,
    opts,
  );

  return query;
};

export const useInvalidateDashboardDataQuery = () => {
  const queryClient = useQueryClient();

  const invalidate = useCallback(
    () => queryClient.invalidateQueries(createDashboardDataCacheKey()),
    [queryClient],
  );

  return invalidate;
};
