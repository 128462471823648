import {
  Center,
  Stack,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { FC, Fragment, useMemo } from 'react';
import { Column, Row, useGroupBy, useTable } from 'react-table';
import { roundToPlaces } from '../../../utils/mathHelpers';
import { millisecondsToHours } from '../../../utils/timeHelpers';
import { ResolutionTimeAndChartData } from './useResolutionTimeTableAndHoursChartQuery';

type TicketStatisticResolutionTimeProps = {
  tableData: ResolutionTimeAndChartData;
  applications: {
    id: string;
    name: string;
    applicationGroupId: string;
  }[];
};

export const TicketStatisticResolutionTimeTable: FC<
  TicketStatisticResolutionTimeProps
> = (props) => {
  const { tableData, applications } = props;
  const { entries } = tableData;

  const columns: Column[] = useMemo(
    () => [
      {
        Header: 'Application',
        accessor: 'appName',
      },
      {
        Header: 'Blocker average resolution time (h)',
        accessor: 'blocker',
      },
      {
        Header: 'Critical average resolution time (h)',
        accessor: 'critical',
      },
      { Header: 'appGroupId', accessor: 'appGroupId' },
    ],
    [],
  );

  const data = useMemo(() => {
    const allEntriesIds = entries
      .filter(
        ({ blockerAverageResolutionTime, criticalAverageResolutionTime }) =>
          blockerAverageResolutionTime > 0 || criticalAverageResolutionTime > 0,
      )
      .map(({ applicationId: id }) => id);

    const appsWithEntries = applications.filter(({ id }) =>
      allEntriesIds.includes(id),
    );

    return appsWithEntries.map((appData) => {
      // TODO: get applicationGroupId from the real response
      const { id, name } = appData;

      const app = entries.find(({ applicationId: appID }) => appID === id) ?? {
        blockerAverageResolutionTime: 0,
        criticalAverageResolutionTime: 0,
      };

      return {
        id,
        appName: name,
        blocker: roundToPlaces(
          millisecondsToHours(app.blockerAverageResolutionTime),
          1,
        ),
        critical: roundToPlaces(
          millisecondsToHours(app.criticalAverageResolutionTime),
          1,
        ),
      };
    });
  }, [applications, entries]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        initialState: {
          groupBy: ['appGroupId'],
          hiddenColumns: ['appGroupId'],
        } as any,
      },
      useGroupBy,
    );

  const renderTableHeader = () => {
    return (
      <Thead backgroundColor="#414042" position="sticky" top={0} zIndex={1}>
        {headerGroups.map((headerGroup) => (
          <Tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <Th
                {...column.getHeaderProps()}
                textTransform="none"
                color="#ffffff"
                fontSize="s"
                fontWeight="normal"
              >
                {column.render('Header')}
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
    );
  };

  const renderTableBody = () => {
    const renderAppGroupRow = (row: Row) => {
      const columnsLength = columns.length;
      const { cells, values } = row;
      const [headerGroupName] = cells;

      const { appGroupId } = values;
      const group = applications.find(({ id }) => id === appGroupId);

      if (!group) {
        return null;
      }

      return (
        <Tr
          {...row.getRowProps()}
          key={row.getRowProps().key}
          padding={0}
          boxShadow="lg"
          position="relative"
          color="#808285"
          bg="#e6e7e8"
        >
          <Td
            {...headerGroupName.getCellProps()}
            position="sticky"
            left={0}
            paddingTop={1}
            paddingBottom={1}
            paddingLeft={6}
            colSpan={columnsLength}
            fontSize="s"
          >
            {group.name}
          </Td>
        </Tr>
      );
    };

    const renderAppRow = (row: any) => {
      return row.leafRows.map((leafRow: any) => {
        prepareRow(leafRow);

        return (
          <Tr {...leafRow.getRowProps()}>
            {leafRow.cells.map((cell: any) => {
              return (
                <Td
                  {...cell.getCellProps()}
                  fontSize="s"
                  color="#414042"
                  paddingTop={3}
                  paddingBottom={3}
                  paddingLeft={6}
                >
                  {cell.render('Cell')}
                </Td>
              );
            })}
          </Tr>
        );
      });
    };

    return (
      <Tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <Fragment key={row.getRowProps().key}>
              {renderAppGroupRow(row)}
              {renderAppRow(row)}
            </Fragment>
          );
        })}
      </Tbody>
    );
  };

  return (
    <Stack flex={1}>
      {data.length !== 0 ? (
        <Table {...getTableProps()}>
          {renderTableHeader()}
          {renderTableBody()}
        </Table>
      ) : (
        <Stack flex={1}>
          <Center height="full" width="full">
            There is no app in this scope to show in table
          </Center>
        </Stack>
      )}
    </Stack>
  );
};
