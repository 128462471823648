import { context, RestRequest } from 'msw';
import { MonitorTableData } from '../../pages/applicationMonitorPage/useMonitorTableDataQuery';
import { appGroupNames, appNames } from '../data';

const DISABLE_APPLICATIONS_GROUPS = true;

export const response = (req: RestRequest) => {
  const viewType = req.params.viewType as string;
  const periodCount =
    {
      'last-thirty-days': 30,
      'all-time': 5,
      'last-twelve-months': 12,
    }[viewType] ?? 30;

  const periodType = ({
    'last-thirty-days': 'DAYS',
    'all-time': 'YEARS',
    'last-twelve-months': 'MONTHS',
  }[viewType] ?? 'DAYS') as MonitorTableData['periods']['type'];

  const today = new Date();
  const response: MonitorTableData = {
    // TODO: meta
    periods: {
      type: periodType,
      items: Object.fromEntries(
        Array.from({ length: periodCount }).map((_, i) => {
          const day = (i + 1).toString().padStart(2, '0');
          const month = String(today.getMonth().valueOf()).padStart(2, '0');
          const displayName = day;

          return [
            i,
            {
              displayName,
              value: `${today.getFullYear()}-${month}-${day}T00:00`,
            },
          ];
        }),
      ),
    },
    applications: Object.fromEntries(
      appNames.map((name, index) => [
        index,
        {
          id: String(index),
          applicationGroupId: DISABLE_APPLICATIONS_GROUPS
            ? null
            : String(index % appGroupNames.length),
          name,
          totalUptimePercent: (Math.random() * 100).toFixed(2),
          totalDowntimeMillis: Math.round(1000 * (Math.random() * 170 + 20)),
          numberOfIncidents: Math.round(Math.random() * 10),
        },
      ]),
    ),

    applicationGroups: Object.fromEntries(
      appGroupNames.map((name, index) => [index, { id: String(index), name }]),
    ),

    statuses: (() => {
      let result = {};

      const statuses = [
        'OK',
        'OK',
        'DISRUPTION',
        'UNKNOWN',
        'OUTAGE',
        'OK_WITH_INFORMATION',
        'OK_WITH_INFORMATION',
      ];

      for (let periodId = 0; periodId < periodCount; ++periodId) {
        for (let appId = 0; appId < appNames.length; ++appId) {
          result = {
            ...result,
            [periodId * appNames.length + appId]: {
              status: statuses[Math.round(Math.random() * statuses.length)],
              applicationId: String(appId),
              periodId: String(periodId),
              newsletterSent: appId % 2 === 0,
            },
          };
        }
      }
      return result;
    })(),
  };
  return context.json(response);
};
