import { createIcon } from '@chakra-ui/react';

export const TicketManagementIcon = createIcon({
  displayName: 'TicketManagementIcon',
  viewBox: '0 0 51 50',
  path: [
    <path d="M38 36.765H26.97a.735.735 0 0 1 0-1.47H38a.735.735 0 1 1 0 1.47Z" />,
    <path d="M38 36.765a.735.735 0 0 1-.735-.736v-8.823a.735.735 0 1 1 1.47 0v8.823c0 .406-.329.736-.735.736ZM25.5 16.177H8.588a.735.735 0 0 1 0-1.47H25.5a.735.735 0 0 1 0 1.47Z" />,
    <path d="M8.588 36.765a.735.735 0 0 1-.735-.736V15.441a.735.735 0 0 1 1.47 0V36.03c0 .406-.329.736-.735.736Z" />,
    <path d="M19.622 36.765H8.588a.735.735 0 0 1 0-1.47h11.034a.735.735 0 1 1 0 1.47Z" />,
    <path d="M31.382 41.176H15.206a.735.735 0 0 1 0-1.47h16.176a.735.735 0 0 1 0 1.47Z" />,
    <path d="M26.235 27.941a.735.735 0 0 1-.71-.923l1.12-4.24a.735.735 0 1 1 1.422.375l-.803 3.038 2.754-.689 9.735-9.735-2.08-2.08-7.799 7.8a.735.735 0 1 1-1.04-1.04l8.32-8.32a.757.757 0 0 1 1.04 0l3.119 3.12a.735.735 0 0 1 0 1.04L30.914 26.686a.733.733 0 0 1-.341.193l-4.16 1.04a.738.738 0 0 1-.178.022Z" />,
    <path d="M26.235 27.941a.735.735 0 0 1-.52-1.255l1.56-1.56a.735.735 0 1 1 1.04 1.04l-1.56 1.56a.733.733 0 0 1-.52.215Z" />,
    <path d="M42.873 14.423a.733.733 0 0 1-.52-.215l-3.12-3.12a.735.735 0 0 1 1.04-1.04l3.12 3.12a.735.735 0 0 1-.52 1.255Z" />,
  ],
});
