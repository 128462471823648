import { useCallback } from 'react';
import { AxiosError, AxiosRequestConfig } from 'axios';
import {
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';
import { useApi } from '../../api/ApiProvider';
import { ApiResponse } from '../../api/types';
import {
  AppGroup,
  Id,
  TicketManagementAppData,
} from '../applicationMonitorPage/types';
import { TicketSupportLevel } from './ticketManagementDomain';

export type TicketManagementData = {
  applicationGroups: {
    [key in Id]: AppGroup;
  };
  tickets: TicketManagementAppData[];
};

type QueryResult = ApiResponse<TicketManagementData>;

type UseTicketManagementIssueQueryParams = {
  periodValue: string | null;
  supportLevel: TicketSupportLevel | null;
};

export const createTicketManagementIssueCacheKey = (
  params: UseTicketManagementIssueQueryParams,
) => ['ticketManagement', params];

export const useTicketManagementIssueQuery = (
  params: UseTicketManagementIssueQueryParams,
  opts?: UseQueryOptions<QueryResult, AxiosError>,
  axios?: AxiosRequestConfig,
) => {
  const api = useApi();

  const { periodValue, supportLevel } = params;

  const queryFn = async ({ signal }: { signal?: AbortSignal }) => {
    const { data } = await api.get<QueryResult>(
      `/ticket/management?date=${periodValue ?? ''}&supportLevel=${
        supportLevel ?? ''
      }`,
      {
        ...axios,
        signal,
      },
    );
    return data;
  };

  const query = useQuery<QueryResult, AxiosError>(
    createTicketManagementIssueCacheKey(params),
    queryFn,
    opts,
  );

  return query;
};

export const useInvalidateIncidentDetailsQuery = (
  params: UseTicketManagementIssueQueryParams,
) => {
  const queryClient = useQueryClient();

  const invalidate = useCallback(
    () =>
      queryClient.invalidateQueries(
        createTicketManagementIssueCacheKey(params),
      ),
    [params, queryClient],
  );

  return invalidate;
};
