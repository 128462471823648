import { AxiosRequestConfig } from 'axios';
import { FC, useEffect } from 'react';
import { useLocalStorage } from 'react-use';
import { useApi } from '../api/ApiProvider';
import { AppsOverview } from './dashboardPage/AppsOverview';
import { LoginPage } from './LoginPage';

export type DashboardPageProps = {};

export const DashboardPage: FC<DashboardPageProps> = () => {
  const [username, setUsername] = useLocalStorage<string>('username');
  const [password, setPassword] = useLocalStorage<string>('password');

  const api = useApi();

  useEffect(() => {
    const onRequest = async (config: AxiosRequestConfig) => {
      if (username && password) {
        config.headers = {
          Authorization: `Basic ${btoa(`${username}:${password}`)}`,
        };
      }
      return config;
    };

    const requestInterceptor = api.interceptors.request.use(
      onRequest,
      (error) => Promise.reject(error),
    );

    return () => {
      api.interceptors.request.eject(requestInterceptor);
    };
  }, [api, password, username]);

  if (!username && !password) {
    return (
      <LoginPage
        onSignIn={async (creds) => {
          setUsername(creds.username);
          setPassword(creds.password);
        }}
      />
    );
  }

  return <AppsOverview key={'username'} />;
};
