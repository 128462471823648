import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Center, Checkbox,
  Heading,
  HStack,
  Spinner,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { format } from 'date-fns';
import { FC, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useApplicationsTicketQuery } from '../queries/useApplicationsTicketQuery';
import { calculatePreviousEndedMonths } from '../TicketManagementPage';
import { TicketStatisticsIncidentsGraph } from './ticketStatisticOverview/TicketStatisticIncidentGraph';
import { TicketStatisticTable } from './ticketStatisticOverview/TicketStatisticTable';
import { TicketStatisticTrendsGraph } from './ticketStatisticOverview/TicketStatisticTrendsGraph';

import { useTicketStatisticsTrendChartQuery } from './ticketStatisticOverview/useTicketStatisticsTrendChartQuery';
import { useTicketStatisticsTableAndIncidentsQuery } from './ticketStatisticOverview/useTicketStatisticsTableAndIncidentsQuery';

import { Select, FormLabel, FormControl, SelectProps } from '@dpd-ui/core';

type TicketStatisticOverviewProps = {};

const lastTwelveMonths = calculatePreviousEndedMonths(12).map(
  ([currentYear, currentMonth]) =>
    `${currentYear}-${currentMonth.toString().padStart(2, '0')}-01`,
);

export const TicketStatisticOverview: FC<TicketStatisticOverviewProps> = () => {
  const [searchParams, setSearchParams] = useSearchParams({
    monthValue: lastTwelveMonths[0],
    thirdPartyCause: 'false',
  });

  const paramsDetails = Object.fromEntries(searchParams);
  const { monthValue, selectedAppId, showThirdPartyCause } = paramsDetails;
  const showThirdPartyCauseValue = showThirdPartyCause === "true";

  const applicationsQuery = useApplicationsTicketQuery(
    {},
    {
      params: { screen: 'TICKET_STATISTICS' },
    },
  );

  const statisticQuery = useTicketStatisticsTableAndIncidentsQuery(
    { monthValue },
    {
      enabled: applicationsQuery.isSuccess,
    },
  );

  const trendGraphQuery = useTicketStatisticsTrendChartQuery(
    {
      dateValue: lastTwelveMonths[0],
    },
    {},
  );

  const tableData = useMemo(() => {
    const { data } = statisticQuery;
    if (!data) return;
    if (!selectedAppId || selectedAppId === '') return data;

    return {
      ...data,
      tickets: data.tickets.filter(({ id }) => id === selectedAppId),
    };
  }, [showThirdPartyCauseValue, selectedAppId, statisticQuery]);

    const trendGraphData = useMemo(() => {
        const { data } = trendGraphQuery;
        if (!data) return;
        const severityIdsToShow = data.severities.filter(e => e.thirdPartyCause === showThirdPartyCauseValue)
            .map(e => e.id);

        return {
            ...data,
            entries: data.entries.filter(e => severityIdsToShow.includes(e.severityId)),
        };
    }, [showThirdPartyCauseValue, trendGraphQuery]);

  if (
    statisticQuery.isError ||
    applicationsQuery.isError ||
    trendGraphQuery.isError
  ) {
    return (
      <Center height="full" width="full">
        <Alert
          status="error"
          as={Stack}
          width="full"
          maxWidth="400px"
          borderRadius="lg"
          border="1px solid"
          borderColor="red.300"
        >
          <VStack>
            <AlertIcon />
            <Text>
              Error:
              {((statisticQuery.error || applicationsQuery.isError) as Error)
                .message ?? 'Something went wrong'}
            </Text>
          </VStack>
          <Button onClick={() => window.location.reload()}>
            Refresh the page
          </Button>
        </Alert>
      </Center>
    );
  }

  if (
    statisticQuery.isLoading ||
    applicationsQuery.isLoading ||
    trendGraphQuery.isLoading
  ) {
    return (
      <Center height="full" width="full">
        <Spinner />
      </Center>
    );
  }

  // const trendGraphData = trendGraphQuery.data;

  const setNewSearchParams = (obj: typeof paramsDetails) => {
    setSearchParams({
      ...paramsDetails,
      ...obj,
    });
  };

  const applications = applicationsQuery.data!;

  const allApps = statisticQuery.data!.tickets;
  const applicationsWithAnyIncident = applications.filter(({ id }) => {
    return allApps.some(({ total, id: appID }) => total > 0 && appID === id);
  });

  const renderMonthFilter = () => {
    const options: SelectProps['options'] = lastTwelveMonths.map(
      (isoDate: string) => ({
        label: format(new Date(isoDate), 'MMMM yyyy'),
        value: isoDate,
      }),
    );

    return (
      <FormControl>
        <Select
          options={options}
          onChange={(value) => value !== null && setNewSearchParams({ monthValue: value })}
          value={monthValue}
        />
        <FormLabel>Choose period</FormLabel>
      </FormControl>
    );
  };

  const renderAppsFilter = () => {
    const options: SelectProps['options'] = [
      { label: 'All apps', value: '' },
      ...Array.from(applicationsWithAnyIncident ?? [])
        .sort(({ name: a }, { name: b }) => {
          if (a.toLowerCase() === b.toLowerCase()) return 0;
          return a.toLowerCase() < b.toLowerCase() ? -1 : 1;
        })
        .map((app) => {
          const { id, name } = app;
          return {
            label: name,
            value: id,
          };
        }),
    ];

    return (
      <FormControl>
        <Select
          options={options}
          onChange={(value) => value !== null && setNewSearchParams({ selectedAppId: value })}
          value={selectedAppId ?? ''}
        />
        <FormLabel>Choose applications</FormLabel>
      </FormControl>
    );
  };

  const renderShowThirdPartyCauseFilter = () => {
    return (
        <FormControl>
            <Checkbox
                borderColor="#808285"
                size="lg"
                isChecked={showThirdPartyCauseValue}
                onChange={(value) => setNewSearchParams({ showThirdPartyCause: String(value.target.checked) })}
            >
              <Text fontSize="sm">Show third party issues on chart</Text>
            </Checkbox>
        </FormControl>
    )
  }

  return (
    <Stack flex={1} padding={5} spacing={6}>
      <Heading
        fontWeight="light"
        color="rgba(65, 64, 66, 1)"
        fontSize="1.325rem"
        paddingBottom={5}
      >
        Blocker and critical incidents
      </Heading>

      <HStack maxWidth="50%">
        {renderMonthFilter()}
        {renderAppsFilter()}
      </HStack>

      <Stack flex={1} height={0} overflowY="auto" spacing={10}>
        <Stack
          direction={['column', null, null, null, 'row']}
          minHeight={['800px', null, null, null, '500px']}
          alignItems="stretch"
          spacing={5}
        >
          <Box flex={1}>
            <TicketStatisticsIncidentsGraph
              data={tableData!}
              monthValue={monthValue}
              showThirdPartyCause={showThirdPartyCauseValue}
            />
          </Box>

          <Box flex={1}>
            <TicketStatisticTrendsGraph data={trendGraphData!} showThirdPartyCause={showThirdPartyCauseValue}/>
          </Box>
        </Stack>

      <HStack maxWidth="50%">
          {renderShowThirdPartyCauseFilter()}
      </HStack>

        {statisticQuery.isLoading ? (
          <Center height="full" width="full">
            <Spinner />
          </Center>
        ) : (
          <TicketStatisticTable data={tableData!} monthValue={monthValue} />
        )}
      </Stack>
    </Stack>
  );
};
