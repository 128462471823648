import { useCallback } from 'react';
import { AxiosError } from 'axios';
import {
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';
import { useApi } from '../../../api/ApiProvider';
import { ApiResponse } from '../../../api/types';
import {
  ChartPeriod,
  TrendIncidentDetail,
  TrendIncidentEntry,
} from '../../applicationMonitorPage/types';

export type IncidentsTrendChartQuery = {
  severities: TrendIncidentDetail[];
  periods: ChartPeriod[];
  entries: TrendIncidentEntry[];
};

type QueryResult = ApiResponse<IncidentsTrendChartQuery>;

type UseTrendChartQueryParams = {
  dateValue: string | null;
};

export const createTrendChartCacheKey = (params: UseTrendChartQueryParams) => [
  'ticketStatisticByTrend',
  params,
];

export const useTicketStatisticsTrendChartQuery = (
  params: UseTrendChartQueryParams,
  opts?: UseQueryOptions<QueryResult, AxiosError>,
) => {
  const api = useApi();

  const { dateValue } = params;

  const queryFn = async ({ signal }: { signal?: AbortSignal }) => {
    const { data } = await api.get<QueryResult>(
      `/ticket/incident/chart?date=${dateValue ?? ''}`,
      {
        signal,
      },
    );
    return data;
  };

  const query = useQuery<QueryResult, AxiosError>(
    createTrendChartCacheKey(params),
    queryFn,
    opts,
  );

  return query;
};

export const useInvalidateTrendChartQuery = (
  params: UseTrendChartQueryParams,
) => {
  const queryClient = useQueryClient();

  const invalidate = useCallback(
    () => queryClient.invalidateQueries(createTrendChartCacheKey(params)),
    [params, queryClient],
  );

  return invalidate;
};
